<template>
  <svg
    t="1640588809063"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="72365"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    data-darkreader-inline-fill=""
    :width="size"
    :height="size"
    :class="{
      'gt-animation': true,
      up: up,
      down: down,
      left: left,
      right: right,
    }"
  >
    <path
      d="M476.501333 727.2448L45.397333 298.8032a20.48 20.48 0 0 1 14.404267-34.952533H916.821333a20.48 20.48 0 0 1 14.5408 34.884266l-425.847466 428.4416a20.48 20.48 0 0 1-29.013334 0.068267z"
      fill="#888888"
      p-id="72366"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "ArrowIcon",

  props: {
    size: {
      type: Number,
      default: 15,
    },

    direction: {
      type: String,
      default: "right",
    },
  },

  mounted() {
    this.handleDirection();
  },

  data() {
    return {
      up: false,
      down: false,
      left: false,
      right: true,
    };
  },

  watch: {
    direction: function () {
      this.handleDirection();
    },
  },

  methods: {
    handleDirection: function () {
      this.up = false;
      this.down = false;
      this.left = false;
      this.right = false;

      switch (this.direction) {
        case "up":
          this.up = true;
          break;
        case "down":
          this.down = true;
          break;
        case "left":
          this.left = true;
          break;
        default:
          this.right = true;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  transform: rotate(-90deg);
}

.down {
  transform: rotate(0);
}

.up {
  transform: rotate(-90deg);
}

.left {
  transform: rotate(-180deg);
}
</style>
