import "./index.scss";
import { Variables, MonthsBetw } from "../../../utils";
import Mixins from "../../mixins";

export default {
  name: Variables.name.temporaryHeader,

  props: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeDestroy() {},

  destroyed() {},

  inject: ["pd", "gd"],

  mixins: [Mixins],

  data() {
    return {
      curIndex:0,
    };
  },

  computed: {
    /**
     * @returns 临时任务池宽度
     */
    arrowWidth: function() {
      let v = this.colWidth;
      if (this.colWidth > 50) {
        v = 50;
      }
      return v;
    },
    /**
     * @returns 默认月份
     */
    diffMonth:function() {
      return MonthsBetw(this.gd.start,this.gd.end)
    }
    
  },

  watch: {},

  methods: {},

  components: {},

  render(h) {
    // "临时任务"
    return h(
      "div",
      {
        class: {
          "gt-temporary-header": true,
        },
        style: {
          "--header-height": `${this.pd.headerHeight}px`,
          "background-color": `${
            this.pd.ganttOptions[Variables.key.header][Variables.key.bgColor]
          }`,
          "border-color": `${
            this.pd.ganttOptions[Variables.key.header][
              Variables.key.borderColor
            ]
          }`,
          color: `${
            this.pd.ganttOptions[Variables.key.header][Variables.key.textColor]
          }`
        }
      },
      [
        h("div",{
          class: {
            "gt-temporary-header": true,
          },
        },
        this.$i18n.t('front-gantt-module-temporary-tasks')
        )
      ]
    );
  }
};
