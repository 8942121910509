import axios from '@/config/httpConfig'

//获取对象详细信息
export function getDetail(data) {
    return axios.post("/objectdetail/getDetail", data);
}
//获取相关列表对象信息
export function getRelationList(data) {
    return axios.post("/objectdetail/getRelationList", data);
}
//获取相关列表数据及按钮和字段信息
export function getDetailRelatedItems(data) {
    return axios.post("objectdetail/getDetailRelatedItems", data);
}