// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/gantt/no-data.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".no-data[data-v-2ce9838e]{height:calc(100% - 42px);width:100%;background-color:#fff}.no-data[data-v-2ce9838e],.no-data .leftBox[data-v-2ce9838e]{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex}.no-data .leftBox[data-v-2ce9838e]{width:34vw;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-justify-content:space-around;-ms-flex-pack:distribute;justify-content:space-around;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;border:1px solid #dedcda;padding:10px 0}.no-data .leftBox .imgBox[data-v-2ce9838e]{width:20vh;height:20vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:20vh 20vh}.no-data .leftBox .textBox[data-v-2ce9838e]{font-weight:700;color:#333;font-size:20px}.no-data .leftBox .buttonBox[data-v-2ce9838e]{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center}.no-data .bordrBox[data-v-2ce9838e]{width:9px;background:#ecf5ff}.no-data .rightBox[data-v-2ce9838e]{-webkit-box-flex:1;-webkit-flex:1;-ms-flex:1;flex:1;border:1px solid #dedcda;overflow:hidden}.no-data .rightBox .spanBox[data-v-2ce9838e]{display:inline-block;border-right:1px solid #dedcda;width:20px;height:100%}", ""]);
// Exports
module.exports = exports;
