import "./index.scss";
import { Variables, isNull, uuid } from "../../../utils";
import { Row } from "../../../model";
import Mixins from "../../mixins";
import Slider from "../../temporarySlider";

export default {
  name: Variables.name.temporaryRow,
  /**
   * 每一行的数据
   */
  props: {
    rowData: Row
  },

  beforeCreate () { },

  created () { },

  beforeMount () { },

  mounted () { },

  beforeDestroy () { },

  destroyed () { },

  inject: ["pd", "gd", "root"],

  mixins: [Mixins],

  data () {
    return {};
  },

  computed: {
    /**
     * @returns 背景样式
     */
    backgroundImageStyle: function () {
      if (this.pd.ganttOptions[Variables.key.body][Variables.key.borderColor]) {
        return {
          "background-image": `linear-gradient(270deg, ${this.pd.ganttOptions[Variables.key.body][Variables.key.borderColor]
            } 1px, transparent 0)`
        };
      }
      return {};
    }
  },

  watch: {},

  methods: {},

  components: {
    [Slider.name]: Slider
  },

  render (h) {
    return h(
      "div",
      {
        class: {
          "gt-gantt-row": true,
        },
        style: {
          height: `50px`,
          "border-color": `${this.pd.ganttOptions[Variables.key.body][Variables.key.borderColor]
            }`,
          color: `${this.pd.ganttOptions[Variables.key.body][Variables.key.textColor]
            }`,
        },
        props: {
          key: !isNull(this.rowData) ? this.rowData.uuid : uuid()
        },
        on: {
          click: this.onClickRow,
          dblclick: this.onDbClickRow,
          mouseenter: this.onMouseEnterRow,
          mouseleave: this.onMouseLeaveRow
        }
      },
      !isNull(this.rowData) ? [this.pd.temporaryNode || h(Slider.name)] : null
    );
  }
};
