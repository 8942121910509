import "./index.scss";
import {
  Variables,
  Errors,
  parseNumber,
  getDateInterval,
  getDateOffset,
  dateList
} from "../../utils";
import { GD, PD } from "../../model";

import TableContent from "../table";
import GanttContent from "../gantt";
import OperationDrawer from "../drawer/operationDrawer";

export default {
  name: Variables.name.root,

  props: {
    /**
     * 数据列表
     */
    data: {
      type: Array,
      default: () => []
    },
    /**
     * 数据索引的label，应当确保它是唯一的，如果不是，则会引起渲染错误。
     */
    dataIndex: {
      type: String,
    },
    /**
     * 数据中起始日期的label，默认值：startDate，如果找不到，则不会渲染甘特条
     */
    startKey: {
      type: String,
      default: Variables.key.start
    },
    /**
     * 数据中截止日期的label，默认值：endDate。如果找不到，同时没有起始日期，则不会渲染甘特条
     */
    endKey: {
      type: String,
      default: Variables.key.end
    },
    /**
     * 接收一个表头高度，应该保证大于30，默认值为60
     */
    headerHeight: {
      type: [Number, String],
      default: Variables.size.defaultHeaderHeight,
      validator: v => {
        const r = parseNumber(v) >= Variables.size.defaultMinHeaderHeight;
        if (!r) {
          throw Errors.header +
          Errors.invalidProps +
          `headerHeight should be at least ${Variables.size.defaultMinHeaderHeight}.`;
        }
        return r;
      }
    },
    /**
     * 接收一个内容的行高，应该保证大于20，默认行高30（含1px的border）
     */
    rowHeight: {
      type: [Number, String],
      default: Variables.size.defaultContentRowHeight,
      validator: v => {
        const minR =
          parseNumber(v) >= Variables.size.defaultMinContentRowHeight;
        if (!minR) {
          throw Errors.header +
          Errors.invalidProps +
          `rowHeight should be at least ${Variables.size.defaultMinContentRowHeight}.`;
        }

        const maxR =
          parseNumber(v) <= Variables.size.defaultMaxContentRowHeight;
        if (!maxR) {
          throw Errors.header +
          Errors.invalidProps +
          `rowHeight should be no more than ${Variables.size.defaultMaxContentRowHeight}.`;
        }
        return minR && maxR;
      }
    },
    /**
     * 边框的尺寸，0为不显示，默认为1
     */
    border: {
      type: Number,
      default: 1
    },
    /**
     * 是否显示复选框，默认为隐藏
     */
    showCheckbox: {
      type: Boolean
    },
    /**
     * 是否显示展开按钮，如果为否，则全部展开。默认为是
     */
    showExpand: {
      type: Boolean,
      default: true
    },
    /**
     * 展开所有数据，默认展开。仅当传入了 `showExpand` 才生效
     */
    expandAll: {
      type: Boolean,
      default: true
    },
    /**
     * 甘特图的每一列宽度
     */
    ganttColumnWidth: {
      type: [Number, String],
      default: Variables.size.defaultMinGanttColumnWidth,
      validator: v => {
        const minR =
          parseNumber(v) >= Variables.size.defaultMinGanttColumnWidth;
        if (!minR) {
          throw Errors.header +
          Errors.invalidProps +
          `ganttColumnWidth should be at least ${Variables.size.defaultMinGanttColumnWidth}.`;
        }

        const maxR =
          parseNumber(v) <= Variables.size.defaultMaxGanttColumnWidth;
        if (!maxR) {
          throw Errors.header +
          Errors.invalidProps +
          `ganttColumnWidth should be no more than ${Variables.size.defaultMaxGanttColumnWidth}.`;
        }

        return minR && maxR;
      }
    },
    /**
     * 显示甘特图的今日线
     */
    showToday: {
      type: Boolean,
      default: true
    },
    /**
     *  显示甘特图的周末背景
     */
    showWeekend: {
      type: Boolean,
      default: true
    },
    /**
     * 离开背景
     */
    levelColor: {
      type: Array,
      default: () => {
        return [];
      }
    },
    /**
     * 头部样式
     */
    headerStyle: {
      type: Object,
      defalut: () => {
        return {};
      }
    },
   /**
     * body样式
     */
    bodyStyle: {
      type: Object,
      default: () => {
        return {};
      }
    },
    /**
     * 背景黑色
     */
    dark: {
      type: Boolean,
      default: false
    },
  },

  beforeCreate () { },

  created () {
    /**
     * 处理数据
     */
    this.gd.initData(this.data, this.dataOptions);
    /**
     * 处理shot组件
     */
    this.pd.setNodes(this.$slots.default);
    /**
     * 保存其他参数
     */
    this.saveParams(true);
  },

  beforeMount () {
    this.ganttResizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.initGanttWidth = entry.contentRect.width;
        this.setHeaders();
      }
    });
  },

  mounted () {
    this.rootHeight = this.$el.clientHeight;
    this.ganttResizeObserver.observe(this.$refs.ganttContent.$el);
  },

  updated () {
    this.scrollBarHeight =
      this.$refs.tableContent.$el.clientHeight -
      this.$refs.ganttContent.$el.clientHeight;
  },

  beforeDestroy () { },

  destroyed () {
    this.ganttResizeObserver = null;
  },

  provide () {
    return {
      gd: this.gd,
      pd: this.pd,
      root: this
    };
  },
  inject: { lefeWith: { default: 0.25 } },
  data () {
    return {
      gd: new GD(),
      pd: new PD(),
      initGanttWidth: 0,
      ganttResizeObserver: null,
      columnSliderLeft: 0,
      columnSliderVisible: false,
      scrollBarHeight: 17,//
      offsetTop: 0,
      rootHeight: 500,
      showOperationDrawer: false,
      showMask: false,
      left_width: this.lefeWith,
      isMousemouse: false, // 记录鼠标按下还是抬起
      initWidth: 0 //鼠标距离bar容器左侧的距离
    };
  },

  computed: {
    lwidth () {
      return (this.left_width * 100) + '%'
    },
    bar_width () {
      // 5 是bar的一半宽度
      return `calc(${this.left_width * 100}% - 5px)`
    },
    dataOptions: function () {
      const opt = {};
      opt.isExpand = this.showExpand ? this.expandAll : true;
      opt.startLabel = this.startKey;
      opt.endLabel = this.endKey;
      return opt;
    },

    tableWidth: function () {
      return this.pd.tableHeaders.reduce((p, x) => p + x.width, 0);
    },

    ganttWidth: function () {
      return (
        this.pd.ganttHeaders.length *
        this.pd.ganttOptions[Variables.key.columnWidth]
      );
    },

    offsetBottom: function () {
      return this.offsetTop + this.rootHeight;
    },

    realBorder: function () {
      return parseNumber(this.border, 1);
    },

    // 这样就只渲染一次
    rowFlatData: function () {
      return this.gd.flatData;
    }
  },

  watch: {
    data: function (nv) {
      let item = null;
      const select = this.gd.selected.index;
      if (select > -1) item = this.rowFlatData[select];

      this.gd.diffData(nv, this.dataOptions, item);
      this.setHeaders();

      // 数据发生变化，如果 selectIndex 变为 -1，表示数据已经被删除，选择的行内容需要抛出清空
      if (select > -1 && this.gd.selected.index === -1) {
        this.$emit("row-click", null);
      }
    },

    levelColor () {
      this.saveParams();
    },

    showCheckbox () {
      this.saveParams();
    },
    showExpand () {
      this.saveParams();
    },

    headerHeight () {
      this.saveParams();
    },

    rowHeight () {
      this.saveParams();
    },

    ganttColumnWidth () {
      this.saveParams();
    },

    showToday () {
      this.saveParams();
    },

    showWeekend () {
      this.saveParams();
    },

    headerStyle () {
      this.saveParams();
    },

    bodyStyle () {
      this.saveParams();
    },

    dark () {
      this.saveParams();
    }
  },

  methods: {
    saveParams (init = false) {
      this.pd.showCheckbox = this.showCheckbox;
      this.pd.showExpand = this.showExpand;
      this.pd.headerHeight = this.headerHeight;
      this.pd.levelColor = this.levelColor;
      this.pd.dark = this.dark;

      const opts = {
        [Variables.key.columnWidth]: this.ganttColumnWidth,
        [Variables.key.showToday]: this.showToday,
        [Variables.key.showWeekend]: this.showWeekend,
        [Variables.key.header]: this.headerStyle || {},
        [Variables.key.body]: this.bodyStyle || {}
      };

      if (init) {
        this.pd.rowHeight = this.rowHeight;
        Object.assign(opts, {
          [Variables.key.columnWidth]: this.ganttColumnWidth
        });
      } else {
        Object.assign(opts, {
          [Variables.key.columnWidth]: this.pd.ganttOptions[
            Variables.key.columnWidth
          ]
        });
      }

      this.pd.setGanttOptions(opts);
    },

    setHeaders () {
      const d =
        getDateInterval(this.gd.start, this.gd.end) /
        Variables.time.millisecondOfDay;

      let end = this.gd.end;
      if (
        d * this.pd.ganttOptions[Variables.key.columnWidth] <
        this.initGanttWidth
      ) {
        const offset =
          (this.initGanttWidth -
            d * this.pd.ganttOptions[Variables.key.columnWidth]) /
          this.pd.ganttOptions[Variables.key.columnWidth];

        end = getDateOffset(end, offset * Variables.time.millisecondOfDay);
      }
      let add6Month = this.$moment(new Date(end)).add(6, 'month').endOf('months').valueOf()
      this.pd.setGanttHeaders(dateList(this.gd.start, add6Month));
    },

    handleGanttScroll: function () {
      // this.$nextTick(() => {
      //   this.offsetTop = this.$refs.ganttContent.$el.scrollTop;
      //   this.$refs.tableContent.$el.scrollTop = this.$refs.ganttContent.$el.scrollTop;
      // });
    },

    handleTableScroll: function () {
      // this.$nextTick(() => (this.$refs.ganttContent.$el.scrollTop += delta));
    },
    handelChange () {
      this.left_width -= 0.05

    },
    handleOpenOperationDrawer: function () {
      this.showMask = true;
      this.showOperationDrawer = true;
    },

    /**
     * 点击遮罩层，表示需要取消打开的顶层对话框，这里将所有对话框的显示都置为false即可
     */
    handleClickMask: function () {
      this.showOperationDrawer = false;
      this.showMask = false;
    },

    /**
     * 移动表头宽度，重载位移线的left
     * @param {Number} offset
     */
    handleMoveColumnSlider: function (offset) {
      if (this.columnSliderVisible === false) {
        this.columnSliderVisible = true;
      }
      this.columnSliderLeft = offset - this.$el.offsetLeft;
    },

    /**
     * 隐藏位移线
     */
    handleColumnSliderHidden: function () {
      this.columnSliderVisible = false;
    },

    /**
     * 处理整个表格的右侧拉伸线
     * @param {Event} e
     */
    handleTableSliderMouseDown: function (event) {
      // let offset = 0;
      // const srcX = e.pageX;
      // const w = this.pd.tableHeaders[this.pd.tableHeaders.length - 1].width;

      // document.onmousemove = e => {
      //   let targetX = e.pageX;
      //   // 如果鼠标离从左侧离开浏览器, 那么鼠标的位置停留在浏览器最左侧的位置, 也就是targetX = 0.
      //   if (targetX < 0) {
      //     targetX = 0;
      //   }

      //   // 判断最大值，最大总宽度要给甘特留出一定空间
      //   const space = 100;
      //   const originAllWidth = this.pd.tableHeaders.reduce(
      //     (res, head) => res + head.width,
      //     0
      //   );
      //   const diffWidth = targetX - srcX;
      //   if (originAllWidth + diffWidth > this.$el.clientWidth - space) {
      //     return;
      //   }

      //   // 判断表格宽度的最小值
      //   if (w + targetX - srcX > Variables.size.defaultMinTableColumnWidth) {
      //     // 赋差值
      //     offset = targetX - srcX;
      //     this.handleMoveColumnSlider(targetX);
      //   }
      // };

      // document.onmouseup = () => {
      //   document.onmousemove = document.onmouseup = null;
      //   this.pd.tableHeaders[this.pd.tableHeaders.length - 1].width += offset;
      //   this.handleColumnSliderHidden();
      // };
      this.isMousemouse = true

      this.initWidth = event.pageX - event.srcElement.getBoundingClientRect().left
      // 移动的时候给document绑定事件，在容器外也能移动
      document.addEventListener('mousemove', this.handelMousemove)
      // 在框外停下鼠标不能移动，也给document绑定事件
      document.addEventListener('mouseup', this.handelMouseup)
    },
    handelMousemove (event) {
      if (this.isMousemouse) {
        // event.pageX:鼠标指针相对于该网页的水平位置；getBoundingClientRect().left: 容器距离页面左侧距离
        // MBoffset: 鼠标距离盒子左侧的位置
        // initWidth：鼠标距离bar容器左侧的距离
        let MBoffsetPrec = (event.pageX - this.$refs.wrapper.getBoundingClientRect().left - this.initWidth + this.$refs.bar.offsetWidth / 2) / this.$refs.wrapper.getBoundingClientRect().width

        const min = (this.$refs.bar.offsetWidth / 2) / this.$refs.wrapper.getBoundingClientRect().width
        const max = (this.$refs.wrapper.getBoundingClientRect().width - (this.$refs.bar.offsetWidth / 2)) / this.$refs.wrapper.getBoundingClientRect().width

        if (MBoffsetPrec < min) {
          MBoffsetPrec = min
          return this.left_width = MBoffsetPrec

        } else if (MBoffsetPrec > max) {
          return MBoffsetPrec = max
        }
        this.left_width = MBoffsetPrec
      } else {
        return
      }
    },
    handelMouseup () {
      this.isMousemouse = false
    },
    // ===== 外部接口 =====
    /**
     * 单击行
     */
    IFClickRow: function (data) {
      this.gd.selected = { index: data.uindex, uuid: data.uuid };
      this.$emit("row-click", { ...data.data });
    },

    /**
     * 双击行
     */
    IFDblClickRow: function (data) {
      this.$emit("row-dbl-click", { ...data.data });
    },

    /**
     * 点击checkbox
     */
    IFCheckedRow: function (state, data) {
      this.$emit("row-checked", state, { ...data.data });
    },

    /**
     * 移动甘特滑块
     * @param {Row} data
     * @param {Date, Date} old
     */
    IFMoveSlider: function (data, old) {
      // 抛出接口事件
      this.$emit("move-slider", { ...data.data }, old);
    },
    /**
     * 滑块点击事件
     * @param {Row} data
     */
    IFClickSlider: function (data) {
      // 抛出接口事件
      this.$emit("click-slider", { ...data.data });
    },
    // 完成百分比拖拽
    IFProcessMove: function (data, old) {
      // 抛出接口事件
      let status = null;
      // 根据百分比改变状态
      if (data.data.progress == 0) {
        status = "未开始";//"未开始"
      } else if (data.progress == 1) {
        if (data.data.type === "cloudccTask") {
          status = '已完成'; //完成
        } else {
          status = '完成'; //完成
        }
      } else {
        status = '进行中'; //"进行中"
      }
      data.data.status = status
      this.$emit("move-process", { ...data.data }, old);
    },
    /**
     * 如果 "今天" 不在甘特范围内，跳转时触发该异常
     */
    INoTodayError: function () {
      this.$emit("no-today-error");
    },

    // ===== 对外方法 =====
    /**
     * 使选择一行
     */
    setSelected: function (data) {
      this.gd.setSelected(data);
    }
  },

  components: {
    [TableContent.name]: TableContent,
    [GanttContent.name]: GanttContent,
    [OperationDrawer.name]: OperationDrawer
  },

  render (h) {
    return h(
      "div", {
      ref: 'wrapper',
      class: {
        "gt-root": true,
        "gt-bg-dark": this.dark
      },
      style: {
        "--root-border": `${this.realBorder}px`
      }
    }, [
      // 拖动表头大小时的位移线
      // h("div", {
      //   class: {
      //     "gt-column-slider-line": true,
      //     "gt-header-border-dark": this.dark,
      //       "gt-hide": !this.columnSliderVisible
      //   },
      //   style: {
      //     left: `${this.columnSliderLeft}px`
      //   }
      // }),

      // 表格右侧的移动线
      h("div", {
        ref: "bar",
        class: {
          "gt-table-slider-line": true,
          "gt-table-slider-line-dark": this.dark
        },
        style: {
          left: this.bar_width
        },
        on: {
          mousedown: this.handleTableSliderMouseDown
        }
      }, [
        h("svg", {
          class: {
            "icon": true,
            "gt-table-slider-line-i": true
          },
          attrs: {
            'aria-hidden': true,
          }
        },
          [
            h("use", {
              attrs: {
                'href': '#icon-tiaozheng',
              }
            }),
          ])
      ]),

      h(TableContent.name, {
        ref: "tableContent",
        style: {
          width: this.lwidth
        },
        props: {
          tableWidth: this.lwidth,
          rowData: this.rowFlatData,
          scrollBarHeight: this.scrollBarHeight
        },
        on: {
          tableScroll: this.handleTableScroll,
          click: this.handelChange
        }
      }),
      h(GanttContent.name, {
        ref: "ganttContent",
        style: {
          left: this.lwidth
        },
        props: {
          tableWidth: this.lwidth,
          ganttWidth: this.ganttWidth,
          rowData: this.rowFlatData,
        },
        on: {
          ganttScroll: this.handleGanttScroll,
          openOperationDrawer: this.handleOpenOperationDrawer
        }
      }),

      // 操作抽屉
      // h(OperationDrawer.name, {
      //   props: {
      //     showDrawer: this.showOperationDrawer,
      //     settingsSlot: this.$slots ? .settings ? .[0]
      //   }
      // }),

      // 整体的遮罩层
      // h("div", {
      //   class: {
      //     "gt-mask-show": this.showMask,
      //       "gt-mask-hide": !this.showMask
      //   },
      //   on: { click: this.handleClickMask }
      // })
    ]
    );
  }
};