
<template>
<!-- src\components\cloudcc-gantt\components\sorting\index.vue -->
  <div class="table-container">
    <div class="top-title">
      <span class="span-title">
        <!-- 重新排序里程碑 -->
        {{$t('front-gantt-module-reorder-milestones')}}</span>
      <div>
        <el-button size="mini"  @click="cancleSorting">
          <!-- 取消 -->
          {{$t('label.emailsync.button.cancel')}}</el-button>
        <el-button size="mini" type="primary" @click="saveSorting">
          <!-- 保存顺序 -->
          {{$t('front-gantt-module-save-order')}}</el-button>
        
      </div>
    </div>
    <el-table
      ref="dragTable"
      :data="tableData"
      border
      :row-class-name="tableRowClassName"
    >
    <el-table-column prop="sortNumber" label="" width="200" >
          <template slot-scope="scope">
          <!-- <i  class="move el-icon-s-operation"></i> -->
          <div class="felxBox">
          <!-- 上浮svg -->
          <svg
                      class="move SvgBox"
                      aria-hidden="true"
                       v-show="scope.row.sortNumber===overVal"
                      @mouseout="changSvgOut"
                    >
                      <use
                        href="#icon-Reorder1"
                      ></use>
                    </svg>
           <!-- 离开svg -->
          <svg
                      class="move SvgBox"
                      aria-hidden="true"
                      v-show="scope.row.sortNumber!==overVal"
                      @mouseover="changSvgOver(scope.row.sortNumber)"
                    >
                      <use
                        href="#icon-Reorder"
                      ></use>
                    </svg>
          <el-input v-model.number="scope.row.sortNumber" placeholder="" class="indexBox" @focus="getFocus(scope.row.sortNumber)" @blur="sortFun(scope.row.sortNumber)"></el-input>
          </div>
        </template>
      </el-table-column>
      <!-- 里程碑 -->
      <el-table-column prop="name" :label="$t('component.milestones.label.title')"  >
          <!-- <template slot-scope="scope">
          <el-button type="text" size="small" class="move">{{scope.row.name}}</el-button> 
        </template>-->
      </el-table-column>
      <!-- 负责人 -->
      <el-table-column prop="principalccname" :label="$t('label_person_in_charge')" ></el-table-column>
      <!-- 开始日期 -->
      <el-table-column prop="startDate" :label="$t('label.chatter.startDate')"></el-table-column>
      <!-- 结束日期 -->
      <el-table-column prop="endDate" :label="$t('label.chatter.endData')" ></el-table-column>
    </el-table>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import {getMilestoneSort,saveMilestoneOrTaskSort} from "./api"
export default {
  props:{
    projectId:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      overVal:NaN, // 当前上浮行排序号sortNumber
      indexVal: '', // 当前排序号
      tableData: []
    };
  },
  created(){
     // 获取里程碑排序数据
    this.getMilestoneSortData()
  },
  methods: {
    
    // 获取里程碑排序数据
    getMilestoneSortData(){
      getMilestoneSort({id:this.projectId}).then(res=>{
        if(res.result){
          this.tableData=res.data
        }
      })
    },
    // svg离开样式
    changSvgOut(){
      this.overVal=NaN
    },
    // svg上浮样式
    changSvgOver(val){
      this.overVal=val
    },
    // 输入改变排序获取焦点
    getFocus(val){
      this.indexVal=val
    },
    // 输入改变排序失去焦点
    sortFun(list){
      
      // 输入排序号不是数字或者数据值没变
      if(typeof list!=='number'||Number(list)===this.indexVal){
        if(typeof list!=='number'){
          this.tableData[this.indexVal-1].sortNumber=this.indexVal
          // '请输入有效号码重新排序里程碑'
          this.$message.error(this.$i18n.t('front-gantt-module-please-reorder-milestones'))
        }
        return
      }
      if(list===0||Number(list)>this.tableData.length||parseInt(list)!==list){
        this.tableData[this.indexVal-1].sortNumber=this.indexVal
        // 此项目仅有${this.tableData.length}个里程碑
        this.$message.error(this.$i18n.t('front-gantt-module-project-has-only',{n:this.tableData.length}))
        return
      }
      let itemAry =this.tableData.splice(this.indexVal-1,1)
      this.tableData.splice(list-1,0,itemAry[0])
      this.tableData.forEach((item,index)=>{
        item.sortNumber=index+1
      })
    },
    cancleSorting(){
      this.$emit("cancleSorting")
    },
    saveSorting(){
      // 保存任务或者里程碑的排序
      let params={data:JSON.stringify({parent:this.projectId,apiData:this.tableData}),objectApi:"cloudccMilestone"}
      saveMilestoneOrTaskSort(JSON.stringify(params)).then(res=>{
        if(res.result){
          // 关闭子组件
          this.$emit("saveSorting")
        }else{
          this.getMilestoneSortData()
        }
      })
    },
    // 创建sortable实例
    initSortable() {
      // 获取表格row的父节点
      const ele = this.$refs.dragTable.$el.querySelector(
        ".el-table__body > tbody"
      );
      // 创建拖拽实例
      // eslint-disable-next-line no-unused-vars
      let dragTable = Sortable.create(ele, {
        animation: 150, //动画
        handle: ".move", //指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
        filter: ".disabled", //指定不可拖动的类名（el-table中可通过row-class-name设置行的class）
        dragClass: "dragClass", //设置拖拽样式类名
        ghostClass: "ghostClass", //设置拖拽停靠样式类名
        chosenClass: "chosenClass", //设置选中样式类名
        // 开始拖动事件
        onStart: () => {
          // 
        },
        // 拖动更新事件
        // 拖动时改变了dom，导致和虚拟dom不一致，页面拖动后改变数据，表格拖动会变回来，需要先删除dom，再插入，然后改变数据顺序
        onUpdate: (e) => {
          let newIndex=e.newIndex,
          newItem=ele.children[newIndex]
          let oldIndex=e.oldIndex,
          oldItem=ele.children[oldIndex]
          // 先删除移动的节点
          ele.removeChild(newItem)
          // 再插入移动的节点到原有节点，还原移动的操作
           if(newIndex > oldIndex) {
                        ele.insertBefore(newItem,oldItem)
                    } else {
                       ele.insertBefore(newItem,oldItem.nextSibling)
                    }
          // 更新数组
           let ary=this.tableData.splice(oldIndex,1)
          
          this.tableData.splice(newIndex,0,ary[0])
          // 改变排序字段值
          this.tableData.forEach((item,index)=>{
            item.sortNumber=index+1
          })
          
          // 
         
        },
      });
    },
    // 设置表格row的class
    tableRowClassName({ row }) {
      if (row.disabled) {
        return "disabled";
      }
      return "";
    },
  },
  mounted() {
    this.initSortable();
  },
};
</script>
<style lang='scss' scoped>
.felxBox{
  display: flex;
  justify-content: center;
  align-items: center;
  .SvgBox{
  width: 15px;
  height: 20px;
  cursor: move;
  }
}

.table-container{
  background: #fff;
  margin: 10px;
  padding: 0 20px;
}
::v-deep .el-table tr th{
  // 表格头样式
  height: 50px;
  background: #FAFAF9;
}
::v-deep .el-table td{
  padding: 4px 0;
}
.indexBox{
  margin-left: 20px;
  width: 70px;
  ::v-deep .el-input__inner{
    height: 34px;
    line-height: 34px;
  }
}
// 拖拽
.dragClass {
  border: 1px solid #c2951a;
}
// 停靠
.ghostClass {
  border: 1px solid #6bc21a;
}
// 选择
.chosenClass:hover > td {
  border: 1px solid #1a2bc2;
}
.top-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .span-title{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    ::v-deep .el-button{
      padding: 10px 16px;
    }
  }
}
</style>