import axios from '@/config/httpConfig'
//获取项目管理主页甘特图
export function getPIdMilestone(data) {
    return axios.post('/ganttChart/getPIdMilestone', data)
}
// 保存任务或者里程碑的排序
export function saveMilestoneOrTaskSort(data) {
    return axios.post("/projectGanttChart/saveMilestoneOrTaskSort", data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 更新视图列表记录
export function updateViewListAjax(data) {
    return axios.post('/view/list/updateViewListAjax', data)
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}