<template>
  <div class="gattContainer" id="gattContainer">
    <fullscreen
      ref="fullscreen"
      class="gattContainer-fullscreen"
      :wrap="true"
      @change="fullscreenChange"
    >
      <div v-show="!showSorting" class="gattContainer-gantt">
        <div class="top-div" v-if="!isHomePage">
          <!-- <DownList class="cardPro-container" @SelectedItem="SelectedItem"/> -->
          <!-- 滚动到临时任务 -->
          <div class="screenfullImg-container">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('front-gantt-module-rolling-temporary-tasks')"
              placement="top"
              :open-delay="openDelay"
            >
              <!-- <i
                @click="handleTemScroll"
                class="scroll-i el-icon-s-operation"
              ></i> -->
              <svg
                class="icon scroll-i el-icon-s-operation"
                aria-hidden="true"
                @click="handleTemScroll"
              >
                <use href="#icon-linshirenwu"></use>
              </svg>
            </el-tooltip>
            <!-- 全屏图标 -->
            <svg class="icon screenfullImg" @click="toggle" aria-hidden="true">
              <use :href="fullscreenSvg"></use>
            </svg>
          </div>
        </div>
        <!-- 有数据 -->
        <div
          :style="{ height: !isHomePage ? 'calc(100% - 50px)' : '100%' }"
          v-show="!isShowNoData"
        >
          <JGantt
            v-loading="ganttoading"
            ref="gantt"
            header-height="60"
            row-height="30"
            data-index="index"
            expand-all
            :dark="isDark"
            :show-checkbox="showCheckbox"
            :show-weekend="showWeekend"
            :show-today="showToday"
            :show-expand="showExpand"
            :data="dataList"
            :header-style="headerStyle"
            :body-style="bodyStyle"
            :level-color="levelColor"
            @row-click="rowClick"
            @row-dbl-click="rowDblClick"
            @row-checked="rowChecked"
            @move-slider="moveSlider"
            @click-slider="clickSlider"
            @move-process="moveProcess"
            @no-today-error="noTodayError"
          >
            <JGanttSlider
              flat
              label="startDate"
              empty-data=""
              bg-color="orange"
              :move="true"
              :resize-left="true"
              :resize-right="true"
              :linked-resize="false"
            >
              <template v-slot:content="data">
                <div
                  class="innerGanttSlider"
                  :curId="data.id"
                  :style="{
                    'background-color': data.color,
                  }"
                >
                  <span class="gantt-text">{{
                    !data.start_date || !data.end_date
                      ? ""
                      : data.text +
                        "--" +
                        (data.user ? data.user : "Unassigned User") +
                        "--" +
                        (data.progress || data.progress == 0
                          ? data.progress * 100 + "%"
                          : "")
                  }}</span>
                </div>
              </template>
            </JGanttSlider>
            <!-- 标题 -->
            <JGanttColumn :label="$t('label.titlename')" :width="titleWith">
              <template v-slot="data">
                <div style="height: 100%; width: 100%">
                  <!-- 拖放以重新排序任务双虚线 -->
                  <div
                    v-show="isShow"
                    :class="{
                      borderBox:
                        data.type == 'cloudccTask' ||
                        data.type == 'cloudccSubtask' ||
                        data.type == 'add',
                    }"
                    @mouseenter="mouseenter()"
                    @mouseleave="mouseleave()"
                  ></div>
                  <!-- 添加 -->
                  <div v-if="data.type == 'add'" class="addOne">
                    <div
                      v-if="data.addType == 'addInput'"
                      class="addInput"
                      :style="{ width: curInputWidth() }"
                    >
                      <el-input
                        v-model="data.text"
                        :placeholder="placeholderData"
                        ref="addInput"
                        @keyup.enter.native="handleAdd(data)"
                      ></el-input>
                      <i class="el-icon-more" @click="handleNew(data)"></i>
                      <i class="el-icon-close" @click="handleClose(data)"></i>
                    </div>
                    <div
                      class="addTaskList"
                      v-if="data.addType == 'addTaskList'"
                      @click="addTaskList(data)"
                    >
                      <!-- 添加任务 -->
                      {{
                        $t("label.emailobject.emaildetail.nextactivity.task")
                      }}
                    </div>
                    <!-- <div v-if="data.addType == 'addMilestone'"   @click="addTaskList(data)">添加里程碑</div> -->
                  </div>

                  <div class="first-column" v-if="data.type != 'add'">
                    <div class="first-column-l">
                      <svg
                        v-if="data.type == 'CloudccProject'"
                        class="icon"
                        aria-hidden="true"
                      >
                        <use href="#icon-xiangmu"></use>
                      </svg>
                      <svg
                        v-if="data.type == 'cloudccMilestone'"
                        class="icon"
                        aria-hidden="true"
                      >
                        <use href="#icon-lichengbei1"></use>
                      </svg>
                      <span class="task-sorting">
                        <svg
                          v-if="
                            (data.type == 'cloudccTask' ||
                              data.type == 'cloudccSubtask') &&
                            isShow
                          "
                          class="icon move"
                          aria-hidden="true"
                        >
                          <use href="#icon-Reorder1"></use>
                        </svg>
                        <!-- 拖动任务排序 -->
                        <el-tooltip
                          v-if="
                            (data.type == 'cloudccTask' ||
                              data.type == 'cloudccSubtask') &&
                            !isShow
                          "
                          class="item"
                          effect="dark"
                          :content="$t('front-gantt-module-drag-task-sorting')"
                          placement="top"
                          :open-delay="openDelay"
                        >
                          <svg class="icon move" aria-hidden="true">
                            <use href="#icon-Reorder1"></use>
                          </svg>
                        </el-tooltip>
                      </span>
                      {{ data.text ? data.text : "-" }}
                    </div>
                    <div class="first-column-r" v-if="data.id !== 'none'">
                      <!-- 里程碑排序 -->
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('front-gantt-module-milestone-sorting')"
                        placement="top"
                        :open-delay="openDelay"
                      >
                        <el-button
                          type="text"
                          @click="handleSort"
                          class="txtBtn"
                          v-if="data.type == 'cloudccMilestone'"
                        >
                          <!-- 里程碑排序 -->
                          {{ $t("front-gantt-module-milestone-sorting") }}
                        </el-button>
                      </el-tooltip>
                      <!-- 信息 -->
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('label.info')"
                        placement="top"
                        :open-delay="openDelay"
                      >
                        <!-- <i
                      class="el-icon-platform-eleme"
                      @click="getDetial(data)"
                    ></i> -->
                        <Popover-cpn
                          :recordObj="recordObj"
                          :content="contentPopover"
                          @jumpDetail="getDetial(data)"
                        ></Popover-cpn>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="getTip(data, 1)"
                        placement="top"
                        :open-delay="openDelay"
                      >
                        <!-- <i class="el-icon-warning" @click="setScroll(data)"></i>icon-fuwubianpai-lianjiexian -->
                        <svg
                          class="icon i"
                          aria-hidden="true"
                          @click="setScroll(data)"
                        >
                          <use href="#icon-tiaozhuan"></use>
                        </svg>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="getTip(data, 2)"
                        placement="top"
                        :open-delay="openDelay"
                      >
                        <!-- <i class="el-icon-edit" @click="editItem(data)"></i> -->
                        <svg
                          class="icon i"
                          aria-hidden="true"
                          @click="editItem(data)"
                        >
                          <use href="#icon-bianji1"></use>
                        </svg>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="getTip(data, 3)"
                        placement="top"
                        :open-delay="openDelay"
                        v-if="
                          data.type != 'CloudccProject' &&
                          data.type != 'cloudccSubtask'
                        "
                      >
                        <!-- <i class="el-icon-plus" @click="addProject(data)"></i> -->
                        <svg
                          class="icon i"
                          aria-hidden="true"
                          @click="addProject(data)"
                        >
                          <use href="#icon-tianji"></use>
                        </svg>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </JGanttColumn>
            <!-- 完成百分比 -->
            <JGanttColumn
              :label="$t('label.projectManagement.percent.complete')"
              center
            >
              <template v-slot="data">
                <div class="gantt-lists">
                  <!-- <el-select v-model="data.progress" placeholder="">
                  <el-option
                    v-for="item in progressOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
                  {{
                    data.progress || data.progress == 0
                      ? data.progress * 100 + "%"
                      : "-"
                  }}
                  <!-- 行内编辑 -->
                  <!-- 完成百分比 -->
                  <svg
                    v-if="data.type !== 'add'"
                    class="icon i line-edit"
                    aria-hidden="true"
                    @click="
                      lineEditing(
                        data,
                        `${
                          data.type == 'CloudccProject'
                            ? 'complete_percentage'
                            : 'fulfill_percent'
                        }`,
                        $t('label.chatter.state')
                      )
                    "
                  >
                    <use href="#icon-bianji1"></use>
                  </svg>
                </div>
              </template>
            </JGanttColumn>
            <!-- 负责人 -->
            <JGanttColumn
              :label="$t('label_person_in_charge')"
              center
              width="200"
            >
              <template v-slot="data">
                <div class="gantt-lists">{{ data.user ? data.user : "-" }}</div>
                <svg
                  v-if="data.type !== 'CloudccProject' && data.type !== 'add'"
                  class="icon i line-edit"
                  aria-hidden="true"
                  @click="
                    lineEditing(data, 'principal', $t('label_person_in_charge'))
                  "
                >
                  <use href="#icon-bianji1"></use>
                </svg>
              </template>
            </JGanttColumn>
            <!-- 状态 -->
            <JGanttColumn :label="$t('label.chatter.state')" width="200" center>
              <template v-slot="data">
                <div class="gantt-lists">
                  {{ data.status ? data.status : "-" }}
                </div>
              </template>
            </JGanttColumn>
            <!-- 计划天数 -->
            <JGanttColumn
              :label="$t('label.projectManagement.planned.days')"
              center
            >
              <template v-slot="data">
                <div class="gantt-lists">
                  {{ data.continue_date ? data.continue_date : "-" }}
                </div>
              </template>
            </JGanttColumn>
            <!-- 开始日期 -->
            <JGanttColumn
              :label="$t('label.chatter.startDate')"
              center
              width="200"
            >
              <template v-slot="data">
                <div class="gantt-lists">
                  {{ data.start_date ? formatDate(data.start_date) : "-" }}
                </div>
                <svg
                  v-if="data.type !== 'add'"
                  class="icon i line-edit"
                  aria-hidden="true"
                  @click="
                    lineEditing(
                      data,
                      'start_date',
                      $t('label.chatter.startDate')
                    )
                  "
                >
                  <use href="#icon-bianji1"></use>
                </svg>
              </template>
            </JGanttColumn>
            <!-- 结束日期 -->
            <JGanttColumn
              :label="$t('label.chatter.endData')"
              center
              width="200"
            >
              <template v-slot="data">
                <div class="gantt-lists">
                  {{ data.end_date ? formatDate(data.end_date) : "-" }}
                </div>
                <svg
                  v-if="data.type !== 'add'"
                  class="icon i line-edit"
                  aria-hidden="true"
                  @click="
                    lineEditing(data, 'end_date', $t('label.chatter.endData'))
                  "
                >
                  <use href="#icon-bianji1"></use>
                </svg>
              </template>
            </JGanttColumn>
            <!-- 优先级 -->
            <JGanttColumn
              label="endDate"
              :name="$t('label.chatter.priority')"
              center
            >
              <template v-slot="data">
                <span>{{ data.priority ? data.priority : "-" }}</span>
                <svg
                  v-if="
                    data.type == 'cloudccTask' ||
                    (data.type == 'cloudccSubtask' && data.type !== 'add')
                  "
                  class="icon i line-edit"
                  aria-hidden="true"
                  @click="
                    lineEditing(data, 'priority', $t('label.chatter.priority'))
                  "
                >
                  <use href="#icon-bianji1"></use>
                </svg>
              </template>
            </JGanttColumn>
            <!-- 计费 -->
            <JGanttColumn
              label="endDate"
              :name="$t('label.projectManagement.charging')"
              center
            >
              <template v-slot="data">
                <span>{{ data.billing ? data.billing : "-" }}</span>
                <svg
                  v-if="
                    data.type == 'cloudccTask' ||
                    (data.type == 'cloudccSubtask' && data.type !== 'add')
                  "
                  class="icon i line-edit"
                  aria-hidden="true"
                  @click="
                    lineEditing(
                      data,
                      'billing',
                      $t('label.projectManagement.charging')
                    )
                  "
                >
                  <use href="#icon-bianji1"></use>
                </svg>
              </template>
            </JGanttColumn>
          </JGantt>
        </div>
        <!-- 无数据甘特图 -->
        <no-data
          :projectId="projectId"
          @handleNew="handleNew"
          v-show="isShowNoData"
          @toUpdate="toUpdate"
        ></no-data>
        <!-- <button @click="handleClickBg">修改背景色</button>
        <button @click="handleClickModify">修改</button>
        <button @click="handleClickInsert">插入</button>
        <button @click="handleClickInsert2">插入2</button>
        <button @click="handleClickDelete">删除</button>
        <button @click="handleClickColor">切换颜色</button>
        <button @click="() => (showCheckbox = !showCheckbox)">
          显示checkbox
        </button>
        <button @click="() => (showWeekend = !showWeekend)">显示weekend</button>
        <button @click="() => (showToday = !showToday)">显示today</button>
        <button @click="() => (showExpand = !showExpand)">显示expand</button>
        <button @click="setSelected">设置选择</button> -->
      </div>
      <Sorting
        v-if="showSorting"
        class="gattContainer-sort"
        :projectId="projectId"
        @cancleSorting="cancleSorting"
        @saveSorting="saveSorting"
      ></Sorting>
      <!-- 新增/修改记录 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :objectApi="objectApi"
        :object-name="tabName"
        :realObjId="'projectworklist'"
        :relativeRecordId="relativeRecordId"
        :relationFieldId="relationFieldId"
        :id="id"
        :type="type"
        :orderNumber="orderNumber"
        @save="save"
        @update="toUpdate"
        @reloadgatee="handleReload"
      >
      </create-edit-obj>
      <!-- 表格内联编辑 -->
      <el-dialog
        :append-to-body="false"
        :title="$t('label.change')"
        :visible.sync="dialogVisible"
        top="15%"
        width="30%"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        class="edit_box"
      >
        <p
          class="pull-left"
          style="height: 30px; line-height: 30px; margin-right: 10px"
        >
          {{ field }}
        </p>
        <editable-cell
          ref="editableCell"
          :field="field"
          :options="editableCellOptions"
          :value="toChangeValue"
          :isFilter="false"
          :input-type="inputType"
          :err-tip="errTip"
          :precision="precision"
          :min="min"
          :max="max"
          origin-type="table"
          width="95%"
          @remoteSearch="remoteSearch"
        >
        </editable-cell>

        <p
          style="text-align: left; padding-top: 10px; clear: both"
          v-if="showApply2Others"
        >
          <!-- 是否应用于选中数据 -->
          <el-checkbox v-model="changeAll">{{
            $t("vue_label_commonobjects_view_change_all_selected_records")
          }}</el-checkbox>
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="handleCloseDialog">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="saveChange"
            :loading="isSaveBtnLoading"
          >
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        :before-close="beforeClose"
        :append-to-body="false"
      >
        <div
          :style="{
            height: dialogBodyHeight,
            overflow: 'auto',
            padding: '0 20px',
          }"
        >
          <search-table
            ref="searchTable"
            :fieldId="relevantFieldId"
            :checked="multiChecked"
            :relevant-objid="relevantObjId"
            :relevant-prefix="relevantPrefix"
            :projectId="projectId"
            @changeSelect="changeSelect"
          />
        </div>
      </el-dialog>
      <!-- 任务条卡片 -->
      <el-dialog
        :visible.sync="clickSliderActive"
        width="30%"
        :modal="false"
        :modal-append-to-body="false"
        :show-close="false"
        class="selectedTaskDialog"
      >
        <div slot="title" class="header-title" v-if="selectedTask">
          <div class="header-titletext">
            <span>{{ selectedTask.text }}</span>
          </div>
        </div>
        <div class="dialog-center" v-if="selectedTask">
          <div class="dialog-centerleft">
            <p>{{ selectedTask.user }}</p>
            <p>
              {{ formatDate(selectedTask.start_date) }}
              <!-- 至: $t("label.appointment.wizard.subtitle2-2") -->
              {{ $t("label.appointment.wizard.subtitle2-2") }}
              {{ formatDate(selectedTask.end_date) }}
              {{ selectedTask.Nextname }}
              <span v-show="selectedTask.continue_date">
                <!-- 天:$t("label.chatter.day") -->
                （{{ selectedTask.continue_date
                }}{{ $t("label.chatter.day") }}）
              </span>
            </p>
            <p>{{ selectedTask.status }}</p>
          </div>
          <div class="dialog-centerright">
            <el-progress
              type="circle"
              :percentage="parseInt(selectedTask.progress * 10) * 10"
            ></el-progress>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取 消: $t("label.chatter.cancel")-->
          <el-button @click="cancel">{{
            $t("label.chatter.cancel")
          }}</el-button>
          <!-- 确 定: $t("label.chatter.ok")-->
          <el-button type="primary" @click="sure">{{
            $t("label.chatter.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </fullscreen>
    <!-- 浮窗 -->
    <div class="hoverBox" ref="hoverBox" v-show="isShow">
      <!-- 任务名称 -->
      <div class="hoverName">
        {{ this.draggedRowData.text }}-{{ this.draggedRowData.id }}
      </div>
      <div class="hoverText">
        {{ isShowText }}
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import { getPIdMilestone } from "./api";
import * as CommonObjApi from "./api";
import JGantt from "@/components/cloudcc-gantt/components/root";
import JGanttColumn from "@/components/cloudcc-gantt/components/column";
import JGanttSlider from "@/components/cloudcc-gantt/components/slider";
import Sorting from "@/components/cloudcc-gantt/components/sorting/index.vue";
import PopoverCpn from "@/components/cloudcc-gantt/components/PopoverDeTail/index.vue"; //悬浮卡片
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import noData from "@/components/cloudcc-gantt/components/noData/index.vue";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import { formatDate } from "@/components/cloudcc-gantt/utils/date";
import INPUTTYPE from "@/config/enumCode/inputType.js";
import { saveMilestoneOrTaskSort } from "./api";
import fullscreen from "vue-fullscreen"; //全屏
import Vue from 'vue'
Vue.use(fullscreen)
let INDEX = 1;
export default {
  name: "App",
  components: {
    JGantt,
    JGanttColumn,
    JGanttSlider,
    // DownList,
    Sorting,
    PopoverCpn,
    createEditObj,
    noData,
    EditableCell,
  },
  props: {
    // 是否是主页
    isHomePage: {
      type: Boolean,
      default: false,
    },
    proId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      titleWith: 386, // 标题列宽度
      isAddTask: false, // 被替换的是否是添加任务行
      cloudccMilestoneId: "", // 拖动对象所在里程碑id
      isShowText: this.$i18n.t("front-gantt-module-Drag-dropt- reorder-tasks"), // 浮窗展示内容 拖放以重新排序任务！
      isChange: true, // 是否在两个任务（或子任务）之间.true:任务或者子任务排序（包括类型转换）；false:拖拽任务成为替换任务的子任务
      isShow: false, // 是否显示浮窗
      fullscreenSvg: "#icon-quanping", // 全屏图标
      copyDataList: [], // 接口返回的一级的数据，便于计算。（要保持和dataList数据一直）
      tasksortFlag: true, // 任务是否排序成功
      messageText: "", // 任务排序提示语
      draggedRowData: {}, // 当前拖动任务对象
      relatedRowData: {}, // 任务排序时最后一个被替换的对象数据
      isShowNoData: false, // 是否展示noData组件
      projectId: "", // 项目id
      isDark: false,
      dataList: [],
      rowdata: null,
      progressOptions: [
        {
          id: "0%",
          value: "0%",
        },
        {
          id: "10%",
          value: "10%",
        },
        {
          id: "20%",
          value: "20%",
        },
        {
          id: "30%",
          value: "30%",
        },
        {
          id: "40%",
          value: "40%",
        },
        {
          id: "50%",
          value: "50%",
        },
        {
          id: "60%",
          value: "60%",
        },
        {
          id: "70%",
          value: "70%",
        },
        {
          id: "80%",
          value: "80%",
        },
        {
          id: "90%",
          value: "90%",
        },
        {
          id: "100%",
          value: "100%",
        },
      ],
      priorityOptions: [
        {
          id: "高",
          value: this.$i18n.t("label.height"),
        },
        {
          id: "中",
          value: this.$i18n.t("label.dashboardSetup.center"),
        },
        {
          id: "低",
          value: this.$i18n.t("label.low"),
        },
        {
          id: "无",
          value: this.$i18n.t("label.tabpage.nothingz"),
        },
      ],
      billingOptions: [
        {
          id: "否",
          value: this.$i18n.t("label.tabpage.noz"),
        },
        {
          id: "是",
          value: this.$i18n.t("label.tabpage.yesz"),
        },
      ],
      content: null,
      showCheckbox: false, //默认不显示复选框
      showWeekend: true,
      showToday: true,
      showExpand: true,
      showSorting: false,
      curRow: null, //当前操作的行
      curLevel: null, //当前操作的层级
      levelColor: ["#FFFFFF"],
      headerStyle: {
        bgColor: "",
        textColor: "",
      },
      bodyStyle: {
        borderColor: "",
        textColor: "",
        todayColor: "",
        weekendColor: "#DEDCDA",
        hoverColor: "",
        selectColor: "",
      },
      key: "",
      link: [
        { id: 1, source: 1, target: 2, type: "1" },
        { id: 2, source: 3, target: 4, type: "1" },
      ],
      showDetail: false,
      fullscreen: false,
      //记录对象
      recordObj: {
        type: Object,
        default: () => {},
      },
      contentPopover: "", //内容
      tabStyle: "", // 图标key
      id: "",
      prefix: "",
      objectApi: "",
      objectName: "",
      realObjId: "",
      relationFieldId: "",
      relativeRecordId: "",
      type: "active",
      placeholderData: this.$i18n.t("vue_label_homepage_enter_name"), //输入框提示信息  请输入名称
      dragDataList: [],
      changList: [], // 拖动修改任务时，存储需要修改的任务数据参数
      field: "", // 要修改的字段名称
      editableCellOptions: [],
      toChangeValue: null, //要修改的数据
      inputType: "", // 要修改数据显示类型
      errTip: this.$i18n.t("label.vlidaterule.message"),
      precision: 0,
      min: 0,
      max: 0,
      dataId: "",
      changeAll: false,
      ganttoading: false,
      dialogVisible: false,
      showSearchTable: false,
      relevantFieldId: "",
      showApply2Others:'',
      relevantObjId:'',
      multiChecked: false,
      relevantPrefix: "",
      tableAttr: {
        type: Array,
        default: null,
      },
      editableCellAttr: null, //单元格编辑字段信息
      isSaveBtnLoading: false,
      orderNumber: null,
      editDataList: [],
      clickSliderActive: false,
      selectedTask: null, //选中任务信息
      tabName: "",
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      openDelay: 500, //延迟时间
      willInsertAfter: null, //拖拽时判断是在被替换对象的前面还是后面
    };
  },
  watch: {},
  created() {
    // 获取可视区宽度,改变标题行宽度
    this.titleWith = ((document.body.clientWidth - 55) * 25) / 100 + 1;
    this.ganttoading = true;
    this.getPIdMilestoneData();
  },
  mounted() {
    this.initSortable();
    this.combinedScroll();
  },
  methods: {
    formatDate,
    // 优化两侧滚动条同步事件
    combinedScroll() {
      let l = document.querySelector(".gt-table-content");
      let r = document.querySelector(".gt-gantt-content-wrap");
      // 绑定左边的滚动事件
      function bindEventLeft() {
        removeEventRight(); //关键点-先解绑右边
        l.addEventListener("scroll", leftScroll);
      }
      // 绑定右边的滚动事件
      function bindEventRight() {
        removeEventLeft(); //关键点-先解绑左边
        r.addEventListener("scroll", rightScroll);
      }
      // 解绑左边的滚动事件
      function removeEventLeft() {
        l.removeEventListener("scroll", leftScroll);
      }
      // 解绑右边的滚动事件
      function removeEventRight() {
        r.removeEventListener("scroll", rightScroll);
      }
      // 左边滚动时候，右边同步
      function leftScroll() {
        r.scrollTop = l.scrollTop;
      }
      // 右边滚动时候，左边同步
      function rightScroll() {
        l.scrollTop = r.scrollTop;
      }
      l.addEventListener("mouseover", bindEventLeft);
      r.addEventListener("mouseover", bindEventRight);
    },
    getTip(row, type) {
      let tipString = "";
      switch (row.type) {
        // 项目
        case "CloudccProject":
          tipString =
            type == 1
              ? this.$i18n.t("c221")
              : type == 2
              ? this.$i18n.t("c222")
              : type == 3
              ? this.$i18n.t("c223")
              : "";
          break;
        // 里程碑
        case "cloudccMilestone":
          tipString =
            type == 1
              ? this.$i18n.t("c224")
              : type == 2
              ? this.$i18n.t("label.projectManagement.editMilestone")
              : type == 3
              ? this.$i18n.t("front-gantt-module-add-milestone")
              : "";
          break;
        // 任务
        case "cloudccTask":
          tipString =
            type == 1
              ? this.$i18n.t("c225")
              : type == 2
              ? this.$i18n.t("label.task.modify")
              : type == 3
              ? this.$i18n.t("label.emailobject.emaildetail.nextactivity.task")
              : "";
          break;
        // 子任务
        case "cloudccSubtask":
          tipString =
            type == 1
              ? this.$i18n.t("c226")
              : type == 2
              ? this.$i18n.t("c227")
              : type == 3
              ? this.$i18n.t("c228")
              : "";
          break;
      }
      return tipString;
    },
    mouseleave() {
      this.isChange = false;
      if (this.isAddTask) {
        // 拖放以重新排序任务。
        this.isShowText = this.$i18n.t(
          "front-gantt-module-Drag-dropt- reorder-tasks"
        );
      } else {
        // 子任务下不可出现子任务。
        // 拖放（缩进任务），以使其成为子任务。
        this.isShowText =
          this.relatedRowData.type === "cloudccSubtask"
            ? this.$i18n.t("front-gantt-module-subtasks-cannot-subtasks")
            : this.$i18n.t("front-gantt-module-drag-and-drop-subtask");
      }
    },
    mouseenter() {
      this.isChange = true;
      // 拖放以重新排序任务。
      this.isShowText = this.$i18n.t(
        "front-gantt-module-Drag-dropt- reorder-tasks"
      );
    },
    SelectedItem(data) {
      this.projectId = data.id;
      this.getPIdMilestoneData();
    },
    handleCloseDialog() {
      this.toChangeValue = "";
      this.dialogVisible = false;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      let options = [];
      let optionValue = [];
      this.showSearchTable = false;

      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = [row.data.id];
      }

      this.editableCellOptions = options;
      this.toChangeValue = { value: optionValue };
    },
    lineEditing(data, curType, label) {
      let row = JSON.parse(JSON.stringify(data));
      // 编辑单元格
      //项目管理行内编辑根据项目对里程碑、任务、成员做限制
      this.projectId = this.proId;
      // 阻止内联编辑按钮冒泡事件
      event.preventDefault();

      // 根据Id获取数据权限
      CommonObjApi.getPermissionById({ id: row.id }).then((res) => {
        if (res.data.isEdit) {
          // 点击单元格内编辑图标时不跳转
          this.dataId = row.id;
          this.changeAll = false;
          this.editableCellOptions = [];
          let schemefieldType = row.apiData[curType].schemefieldType;
          let schemefieldName = row.apiData[curType].schemefieldName;
          let lookupObj = row.apiData[curType].lookupobj;
          let nameLabel = label;
          this.inputType = INPUTTYPE[schemefieldType];
          this.showApply2Others =
            schemefieldType == "X" || schemefieldType == "MR" ? false : true;
          let valType = curType;
          if (curType == "principal") {
            valType = "user";
          }
          if (
            curType == "complete_percentage" ||
            curType == "fulfill_percent"
          ) {
            valType = "progress";
          }
          let curVal = row[valType];
          // 列表点击日期编辑处理
          if (schemefieldType === "D") {
            this.toChangeValue = { value: curVal ? new Date(curVal) : null };
          } else if (
            curType == "complete_percentage" ||
            curType == "fulfill_percent"
          ) {
            this.toChangeValue = { value: curVal * 100 + "%" };
          } else {
            this.toChangeValue = { value: curVal };
          }
          this.editableCellAttr = row;
          this.editableCellAttr["schemefieldType"] = schemefieldType;
          this.editableCellAttr["schemefieldName"] = schemefieldName;
          this.editableCellAttr["valType"] = valType;
          // 复选框类型无返回值时
          if (schemefieldType === "B") {
            this.toChangeValue = {
              value:
                schemefieldName === undefined
                  ? false
                  : schemefieldName === "true"
                  ? "true"
                  : "false",
            };
          }
          // 查找、主详、查找多选字段
          else if (
            schemefieldType === "Y" ||
            schemefieldType === "M" ||
            schemefieldType === "MR"
          ) {
            this.relevantFieldId = row.id;
            this.relevantObjId = lookupObj;
            this.multiChecked = schemefieldType === "MR" ? true : false;
            this.relevantPrefix = "p11";
            this.editableCellOptions = [];
            if (
              schemefieldName &&
              `${schemefieldName}ccname` &&
              schemefieldType !== "MR"
            ) {
              this.editableCellOptions = [
                {
                  label: row.user,
                  value: schemefieldName,
                },
              ];
            } else if (
              schemefieldName &&
              schemefieldName !== "" &&
              `${schemefieldName}ccname` &&
              schemefieldType === "MR"
            ) {
              let values = row.principal ? row.principal.split(";") : [];
              let labels = row.user ? row.user.split(";") : [];
              values.forEach((item, idx) => {
                this.editableCellOptions.push({
                  label: labels[idx],
                  value: values[idx],
                });
              });
              this.toChangeValue = { value: values };
            }
          } else if (
            schemefieldType === "L" ||
            schemefieldType === "Q" ||
            schemefieldType === "ct"
          ) {
            // 选择/多选/币种类型
            this.editableCellOptions = [];
            let Options = [];
            if (valType === "progress") {
              Options = [...this.progressOptions];
            } else if (valType === "priority") {
              Options = [...this.priorityOptions];
            } else {
              Options = [...this.billingOptions];
            }
            Options.forEach((ele) => {
              this.editableCellOptions.push({
                label: ele.id,
                value: ele.value,
              });
            });
          }
          this.field = nameLabel;
          this.fieldApiName = schemefieldName;
          this.dialogVisible = true;
          this.$refs.editableCell && this.$refs.editableCell.setInputValue();
        } else {
          // 抱歉，您无权编辑该条数据，请联系系统管理员。
          this.$message.warning(this.$i18n.t("label.notEdit.message"));
        }
      });
    },
    // 查找/查找多选
    remoteSearch() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    //格式转化
    format(val) {
      if (val.constructor == Array) {
        return val.join(";");
      } else {
        return val.toString();
      }
    },
    // 保存修改字段
    saveChange() {
      let fields = [];
      let datas = [];
      // TODO:查找和查找多选类型字段值修改
      let value = this.$refs.editableCell.editValue;
      let precision = this.$refs.editableCell.precision;
      let inputType = this.$refs.editableCell.inputType;
      //数字类型保存传值（小数位不够时补0）
      if (inputType === "number" && value) {
        value = value.toFixed(precision);
      }
      let data = { id: this.dataId };

      data[
        this.editableCellAttr.apiname || this.editableCellAttr.schemefieldName
      ] = value === null || value === undefined ? value : this.format(value);
      if (
        this.editableCellAttr.valType == "end_date" ||
        this.editableCellAttr.valType == "start_date"
      ) {
        if (
          (this.editableCellAttr.type == "cloudccMilestone" ||
            this.editableCellAttr.type == "CloudccProject") &&
          !value
        ) {
          // 存在验证错误，请检查填写的内容
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
          return;
        }
        if (!value) {
          data[
            this.editableCellAttr.apiname ||
              this.editableCellAttr.schemefieldName
          ] = "";
        } else {
          if (value instanceof Date) {
            data[
              this.editableCellAttr.apiname ||
                this.editableCellAttr.schemefieldName
            ] = formatDate(value);
          }
        }
      }
      datas.push(data);
      let params = {
        objectApi: this.editableCellAttr.type,
        jsonstr: JSON.stringify(datas),
        fields: fields.toString(),
      };
      //输入值为空时不可保存
      // if (value !== null && value !== undefined && value.toString().trim() != "") {
      this.isSaveBtnLoading = true;
      CommonObjApi.updateViewListAjax(params)
        .then((res) => {
          this.isSaveBtnLoading = false;
          this.dialogVisible = false;
          if (res.result && res.data.updateResultList[0].isSuccess == "true") {
            if (
              res.data !== null &&
              res.data.list &&
              res.data.list.length > 0
            ) {
              let changeItem = res.data.list[0];
              let that = this;
              // eslint-disable-next-line no-inner-declarations
              function getTreeItem(data) {
                data.map((item) => {
                  if (item.id === changeItem.id && item.name !== "") {
                    let changeType = that.editableCellAttr["valType"];
                    if (changeType === "progress") {
                      item[changeType] = Number(value.split("%")[0]) / 100;
                    } else if (changeType === "user") {
                      item.principal = changeItem.principal;
                      item.user = changeItem.principalccname;
                    } else {
                      item[changeType] = value;
                      if (
                        changeType == "start_date" ||
                        changeType == "end_date"
                      ) {
                        item.continue_date = changeItem.continue_date;
                        item[changeType] = value + " 00:00:00";
                      }
                    }
                  } else {
                    if (item.children) {
                      getTreeItem(item.children);
                    }
                  }
                });
                return data;
              }
              this.dataList = [...getTreeItem(this.dataList)];
            }
          }
          // 处理提示语
          let returnInfo = "";
          res.data.updateResultList.forEach((updateResult) => {
            if (updateResult.isSuccess !== "true") {
              returnInfo += this.$i18n.t("vue_label_notice_batchedit_fail", [
                updateResult.id,
                updateResult.errorMessage,
              ]);
              // `id为${updateResult.id}的数据更新失败，失败原因为${updateResult.errormessage};`;
            }
          });
          if (returnInfo === "") {
            this.$message.success(
              this.$i18n.t("vue_label_notice_change_success")
            );
          } else {
            this.$message.error(returnInfo);
          }
        })
        .catch(() => {
          this.isSaveBtnLoading = false;
        });
    },
    curInputWidth() {
      return (
        document.getElementsByClassName("gt-table-content")[0].offsetWidth -
        10 +
        "px"
      );
    },
    // 创建sortable实例
    initSortable() {
      // 获取表格row的父节点
      const ele = document.querySelector(".gt-table-row-wrap > .tableWrap-one");
      // 创建拖拽实例
      // eslint-disable-next-line no-unused-vars
      let dragTable = Sortable.create(ele, {
        animation: 150, //动画
        handle: ".move", //指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
        filter: ".disabled", //指定不可拖动的类名（el-table中可通过row-class-name设置行的class）
        dragClass: "drag", //设置拖拽样式类名
        ghostClass: "ghostClass", //设置拖拽停靠样式类名
        chosenClass: "chosenClass", //设置选中样式类名
        //************* 禁用html5原生拖拽行为
        forceFallback: true,
        // 开始拖动事件
        onStart: (evt) => {
          let hoverBox = document.querySelector(".hoverBox");
          let mainBox = document.getElementById("gattContainer");
          hoverBox.style.top =
            evt.originalEvent.clientY -
            mainBox.offsetTop -
            mainBox.offsetParent.offsetTop +
            "px";
          hoverBox.style.left = evt.originalEvent.clientX + 40 + "px";
          // 显示浮窗
          this.isShow = true;
          // 当前拖动任务对象
          this.draggedRowData = JSON.parse(
            JSON.stringify(evt.item.__vue__._props.rowData.data)
          );
          // 被替换对象
          this.relatedRowData = JSON.parse(
            JSON.stringify(evt.item.__vue__._props.rowData.data)
          );
          // 获取拖动对象所在里程碑id
          if (this.draggedRowData.type === "cloudccTask") {
            this.cloudccMilestoneId = this.draggedRowData.parent;
          } else {
            // 拖放以重新排序任务。
            this.isShowText = this.$i18n.t(
              "front-gantt-module-Drag-dropt- reorder-tasks"
            );
            // 拖动的是子任务
            this.copyDataList.forEach((item) => {
              if (item.id === this.draggedRowData.parent) {
                this.cloudccMilestoneId = item.parent;
              }
            });
          }
          // 行全部数据
          let rowAllData = evt.item.__vue__._props.rowData;
          // 使任务的子任务折叠
          if (
            this.draggedRowData.type === "cloudccTask" &&
            rowAllData.isExpand &&
            rowAllData.children &&
            rowAllData.children.length > 0
          ) {
            rowAllData.isExpand = false;
          }
        },
        // 拖拽中事件
        onMove: (evt, originalEvent) => {
          /*
            evt.dragged; // 被拖拽的对象
            evt.draggedRect; // 被拖拽的对象所在区域 {left, top, right, bottom}
            evt.related; // 被替换的对象
            evt.relatedRect; // DOMRect
            evt.willInsertAfter; // 是在被替换对象的前面还是后面
            originalEvent.clientY; // 鼠标的位置
            */

          //  被替换对象数据
          let relatedRowData = JSON.parse(
            JSON.stringify(evt.related.__vue__._props.rowData.data)
          );
          // 不允许跨里程碑排序,true:相同里程碑，false不同里程碑
          let flag2 = false;
          if (relatedRowData.type === "add") {
            // 添加任务,
            this.isAddTask = true;
            // 拖放以重新排序任务。
            this.isShowText = this.$i18n.t(
              "front-gantt-module-Drag-dropt- reorder-tasks"
            );
          } else if (relatedRowData.type === "cloudccTask") {
            flag2 = this.cloudccMilestoneId === relatedRowData.parent;
          } else if (relatedRowData.type === "cloudccSubtask") {
            // 被替换的是子任务
            let relatedRowDataParentId = "";
            this.copyDataList.forEach((item) => {
              if (item.id === relatedRowData.parent) {
                relatedRowDataParentId = item.parent;
              }
            });
            flag2 = this.cloudccMilestoneId === relatedRowDataParentId;
          }
          if (flag2) {
            // 相同里程碑
            let hoverBox = document.querySelector(".hoverBox");
            let mainBox = document.getElementById("gattContainer");
            hoverBox.style.top =
              originalEvent.clientY -
              mainBox.offsetTop -
              mainBox.offsetParent.offsetTop +
              "px";
            hoverBox.style.left = originalEvent.clientX + 40 + "px";
            //  被替换对象数据
            this.relatedRowData = JSON.parse(
              JSON.stringify(evt.related.__vue__._props.rowData.data)
            );
            // 被替换对象是子任务，改变浮窗展示内容
            // 拖动任务有子任务
            let hasChildren =
              this.draggedRowData.children &&
              this.draggedRowData.children.length > 0 &&
              this.draggedRowData.type === "cloudccTask";
            if (
              (hasChildren && !this.isChange) ||
              (hasChildren &&
                this.isChange &&
                this.relatedRowData.type === "cloudccSubtask")
            ) {
              // 该任务存在子任务，不能成为其他任务的子任务！
              this.isShowText = this.$i18n.t(
                "front-gantt-module-this-task-of-other-tasks"
              );
            } else if (
              this.relatedRowData.type === "cloudccSubtask" &&
              !this.isChange
            ) {
              // 子任务下不可出现子任务。
              this.isShowText = this.$i18n.t(
                "front-gantt-module-subtasks-cannot-subtasks"
              );
            } else if (this.isChange) {
              // 拖放以重新排序任务。
              this.isShowText = this.$i18n.t(
                "front-gantt-module-Drag-dropt- reorder-tasks"
              );
            } else if (this.relatedRowData.type === "cloudccTask") {
              // 拖放（缩进任务），以使其成为子任务。
              this.isShowText = this.$i18n.t(
                "front-gantt-module-drag-and-drop-subtask"
              );
            } else {
              // 拖放以重新排序任务。
              this.isShowText = this.$i18n.t(
                "front-gantt-module-Drag-dropt- reorder-tasks"
              );
            }
          }
          this.willInsertAfter = evt.willInsertAfter;
          return false;
        },
        // 结束拖动事件
        onEnd: () => {
          this.isShow = false;
          // 如果自身本来就是该任务的子任务，则拖动子任务无效
          // 拖动对象和替换对象为同一个，则拖动无效
          if (
            (this.relatedRowData.id === this.draggedRowData.parent &&
              !this.isChange) ||
            this.relatedRowData.id === this.draggedRowData.id
          ) {
            // 回退排序
            this.dataList = [...this.dataList];
          } else {
            // 验证是否排序成功
            this.isSort();
            // 任务排序不成功
            if (!this.tasksortFlag) {
              this.$message.warning(this.messageText);
              // 回退排序
              this.dataList = [...this.dataList];
            } else {
              let apiData = (apiData = this.sortFun());
              this.ganttoading = true;
              // 保存排序接口
              let objectApi = "";
              if (this.isChange || this.isAddTask) {
                objectApi = this.relatedRowData.type;
              } else {
                objectApi = "cloudccSubtask";
              }
              let params = {
                objectApi: objectApi,
                data: JSON.stringify(apiData),
              };
              saveMilestoneOrTaskSort(params).then((res) => {
                if (res.result) {
                  // 重新请求数据
                  this.getPIdMilestoneData();
                }
              });
            }
          }
          // 清空拖动对象和替换对象
          this.isAddTask = false;
          this.draggedRowData = {};
          this.relatedRowData = {};
          this.isChange = true;
          this.tasksortFlag = true;
          this.messageText = "";
        },
      });
    },
    // 判断排序是否符合要求
    isSort() {
      // 拖动任务有子任务
      let hasChildren =
        this.draggedRowData.children &&
        this.draggedRowData.children.length > 0 &&
        this.draggedRowData.type === "cloudccTask";

      // 本身有子任务不能成为其他任务的子任务(true：被拖动有子任务的任务不符合排序)
      let flag1 =
        (hasChildren && !this.isChange) ||
        (hasChildren &&
          this.isChange &&
          this.relatedRowData.type === "cloudccSubtask");
      // 不允许跨里程碑排序(true:不是同一里程碑内部排序)
      let flag2 = false;

      if (this.relatedRowData.type === "cloudccTask") {
        flag2 = this.cloudccMilestoneId !== this.relatedRowData.parent;
      } else {
        // 被替换的是子任务
        let relatedRowDataParentId = "";
        this.copyDataList.forEach((item) => {
          if (item.id === this.relatedRowData.parent) {
            relatedRowDataParentId = item.parent;
          }
        });
        flag2 = this.cloudccMilestoneId !== relatedRowDataParentId;
      }
      // 子任务不能再有子任务
      let flag4 =
        !this.isChange && this.relatedRowData.type === "cloudccSubtask";
      // 任务是否排序成功
      this.tasksortFlag = !(flag1 || flag2 || flag4);
      if (!this.tasksortFlag) {
        if (flag1) {
          this.messageText = this.$i18n.t(
            "front-gantt-module-this-task-of-other-tasks"
          );
        }
        if (flag2) {
          // 任务只能在同一里程碑内排序
          this.messageText = this.$i18n.t(
            "front-gantt-module-drag-one-task-sorting"
          );
        }
        if (flag4) {
          this.messageText = this.$i18n.t(
            "front-gantt-module-subtasks-cannot-subtasks"
          );
        }
      }
    },
    // 里程碑、任务、子任务排序方法
    sortFun() {
      // 任务parentid
      let parentid = "";
      // 被替换对象的父元素下的所有子项
      let apiData = [];
      // 被拖动是add时，全部按照排序处理，放到最后一个
      if (this.isChange || this.isAddTask) {
        // true:任务或者子任务排序（包括类型转换）
        parentid = this.relatedRowData.parent;
        // 判断是否是相同类型转换
        let flag = this.relatedRowData.type !== this.draggedRowData.type;
        // 判断是否是同组转换
        let flag1 = this.relatedRowData.parent === this.draggedRowData.parent;
        // 被拖动对象的父元素下的所有子项
        this.copyDataList.forEach((item) => {
          if (item.parent === parentid) {
            let obj = { sortNumber: item.number, id: item.id };
            apiData.push(obj);
          }
        });
        //  将拖拽项添加到apiData中
        if (flag1) {
          // 同组,调换位置
          let ary1 = apiData.splice(Number(this.draggedRowData.number) - 1, 1);
          if (this.isAddTask) {
            if (this.willInsertAfter) {
              apiData.push(ary1[0]);
            } else {
              apiData.unshift(ary1[0]);
            }
          } else {
            apiData.splice(Number(this.relatedRowData.number) - 1, 0, ary1[0]);
          }

          // 改变排序号
          apiData.forEach((item, index) => {
            item.sortNumber = index + 1 + "";
          });
        } else {
          // 不同组，插入
          if (this.isAddTask) {
            apiData.push({
              sortNumber: this.relatedRowData.number,
              id: this.draggedRowData.id,
              isChangeObject: flag + "",
            });
          } else {
            apiData.unshift({
              sortNumber: this.relatedRowData.number,
              id: this.draggedRowData.id,
              isChangeObject: flag + "",
            });
          }

          // 改变排序号
          apiData.forEach((item, index) => {
            item.sortNumber = index + 1 + "";
          });
        }
      } else {
        // false:拖拽任务成为替换任务的子任务
        parentid = this.relatedRowData.id;
        // 被替换对象的父元素下的所有子项，并排序
        this.copyDataList.forEach((item) => {
          if (item.parent === parentid) {
            let obj = { sortNumber: item.number + 1 + "", id: item.id };
            apiData.push(obj);
          }
        });
        //  将拖拽项添加到apiData中,都是第一个子任务
        let isChangObj = this.draggedRowData.type !== "cloudccSubtask";
        apiData.push({
          sortNumber: "1",
          id: this.draggedRowData.id,
          isChangeObject: isChangObj + "",
        });
      }

      return {
        parent: parentid,
        apiData,
      };
    },
    // 设置表格row的class
    tableRowClassName({ row }) {
      if (row.disabled) {
        return "disabled";
      }
      return "";
    },
    // 不是保存并成功时标准新建成功的回调
    toUpdate() {
      // 隐藏no-data组件
      this.isShowNoData = false;
      // 重新请求数据
      this.getPIdMilestoneData();
    },
    //新建/编辑回调
    save() {
      this.getPIdMilestoneData();
      this.$emit("refreshRelatedList");
    },
    // 标准新建
    handleNew(row) {
      let rowdata = JSON.parse(JSON.stringify(row));
      this.tabName = "";
      this.id = "";
      this.relativeRecordId = rowdata.parent == "none" ? "" : rowdata.parent;
      this.orderNumber = row.number;
      // 任务label.chatter.task
      // 里程碑this.$i18n.t("component.milestones.label.title")
      if (rowdata.type == "cloudccMilestone") {
        this.objectApi = "cloudccMilestone";
        this.relationFieldId = "ffe2020Milestone01";
        this.tabName = this.$i18n.t("component.milestones.label.title");
        this.prefix = "p02";
      }
      if (rowdata.type == "add") {
        this.objectApi = "cloudccTask";
        this.relationFieldId = "ffe20201207task02";
        this.prefix = "p03";
        this.tabName = this.$i18n.t("label.chatter.task");
      }
      this.$nextTick(() => {
        this.$refs.createEditObj.add();
      });
    },
    // input框回车事件，添加
    handleAdd(row) {
      let curId = row.id;
      let curFrom = row.curFrom;
      let parentId = row.parent;
      let type = row.realType;
      let text = row.text;
      let number = row.number;
      let obj = {
        their_project: this.projectId,
        their_milestone: parentId === "none" ? "" : parentId,
        name: text,
        sequence_of_tasks: number, //新建排序字段
      };
      let paramsList = [];
      paramsList.push(obj);
      let params = {
        objectApi: type,
        data: JSON.stringify(paramsList),
        marketsea: "false", //true是公海池新建记录，false不是
      };
      let dataArry = [],
        that = this;
      function getTreeItem(data, id) {
        data.map((item) => {
          if (curFrom == "right") {
            if (item.id == parentId) {
              if (item.children) {
                item.children.forEach((pchild, pindex) => {
                  if (pchild.id == curId) {
                    if (pchild?.addType == "addInput") {
                      // 保存接口
                      CommonObjApi.save(params).then((res) => {
                        if (res.result) {
                          that.$message.success(that.$i18n.t("savesuccess"));
                          that.$emit("refreshRelatedList"); //用于刷新相关列表数据
                          // 模拟成功不刷新页面，刷新数据
                          item.children.splice(pindex, 1, {
                            type: type,
                            id: res.data[0].id,
                            text: text,
                            parent: parentId,
                            children: [],
                            start_date: null,
                            end_date: null,
                            priority: null,
                            progress: 0,
                            status: "未开始",
                            color: "#00DFC7",
                            color2: "#00C7B2",
                            continue_date: null,
                            duration: "",
                            user: "",
                            apiData: {
                              end_date: {
                                schemefieldName: "end_date",
                                schemefieldType: "D",
                                lookupobj: "",
                              },
                              principal: {
                                schemefieldName: "principal",
                                schemefieldType: "MR",
                                lookupobj: "cloudccprojectmember",
                              },
                              priority: {
                                schemefieldName: "priority",
                                schemefieldType: "L",
                                lookupobj: "",
                              },
                              fulfill_percent: {
                                schemefieldName: "fulfill_percent",
                                schemefieldType: "L",
                                lookupobj: "",
                              },
                              status: {
                                schemefieldName: "status",
                                schemefieldType: "L",
                                lookupobj: "",
                              },
                              start_date: {
                                schemefieldName: "start_date",
                                schemefieldType: "D",
                                lookupobj: "",
                              },
                              billing: {
                                schemefieldName: "billing",
                                schemefieldType: "L",
                                lookupobj: "",
                              },
                            },
                          });
                          that.dataList = [...dataArry];
                        }
                      });
                    }
                  }
                });
              }
            } else {
              if (item.children) {
                getTreeItem(item.children, id);
              }
            }
          } else {
            // 点击下方文字按钮del
            if (item.id == id) {
              let addOne = item.children.filter((child) => {
                return child.addType == "addInput";
              });
              if (addOne.length) {
                addOne[0].type = "cloudcctaskList";
                (addOne[0].id = "999"),
                  (addOne[0].parent = item.id),
                  (item.children = [...addOne]);
              }
            } else {
              if (item.children) {
                getTreeItem(item.children, id);
              }
            }
          }
        });
        return data;
      }
      dataArry = getTreeItem(this.dataList, curId);
    },
    // input框X按钮
    handleClose(row) {
      let curId = JSON.parse(JSON.stringify(row)).id;
      let curFrom = JSON.parse(JSON.stringify(row)).curFrom;
      let parentId = JSON.parse(JSON.stringify(row)).parent;
      function getTreeItem(data, id) {
        data.map((item) => {
          if (curFrom == "right") {
            if (item.id == parentId) {
              if (item.children) {
                item.children.forEach((pchild, pindex) => {
                  if (pchild.id == curId) {
                    if (pchild?.addType == "addInput") {
                      item.children.splice(pindex, 1);
                    }
                  }
                });
              }
            } else {
              if (item.children) {
                getTreeItem(item.children, id);
              }
            }
          } else {
            // 点击下方文字按钮del
            if (item.id == id) {
              let addOne = item.children.filter((child) => {
                return child.addType != "addInput";
              });
              item.children = [...addOne];
            } else {
              if (item.children) {
                getTreeItem(item.children, id);
              }
            }
          }
        });
        return data;
      }
      this.dataList = [...getTreeItem(this.dataList, curId)];
    },
    // 获取数据
    getPIdMilestoneData() {
      this.projectId = this.proId;
      getPIdMilestone({ parentId: this.projectId }).then((res) => {
        if (res.result && res.data) {
          // 存储返回数据，便于拖动后计算
          this.copyDataList = JSON.parse(JSON.stringify(res.data.tasks.data));
          // 判断是否无数据
          let isNodData = this.copyDataList.find((item) => {
            if (
              item.type === "cloudccTask" ||
              (item.type === "cloudccMilestone" && item.id !== "none")
            ) {
              return true;
            }
          });
          this.isShowNoData = isNodData === undefined ? true : false;
          // 重组数据
          this.reWriteData(res.data.tasks.data);
          this.$nextTick(() => {
            this.ganttoading = false;
            document.getElementsByClassName(
              "gt-gantt-content-wrap"
            )[0].scrollLeft = 200;
          });
        }
        // 关闭loading
        this.ganttoading = false;
      });
    },
    // 重组数据
    reWriteData(val = []) {
      this.editDataList = JSON.parse(JSON.stringify(val));
      let data = this.translateDataToTree(val);
      getData(data);
      function getData(list) {
        list.forEach((row) => {
          if (row.children) {
            // 里程碑下有任务
            if (row.type == "cloudccMilestone") {
              row.children.push({
                type: "add",
                id: "",
                children: [],
                realType: "cloudccTask",
                addType: "addTaskList", //addMilestone addTask
                parent: row.id,
              });
            }
            return getData(row.children);
          } else {
            row.children = [];
            // 里程碑下无任务
            if (row.type == "cloudccMilestone") {
              row.children.unshift({
                type: "add",
                id: "",
                children: [],
                addType: "addTaskList",
                realType: "cloudccTask", //addMilestone addTask
                parent: row.id,
              });
            }
          }
        });
      }

      this.dataList = data;
    },
    translateDataToTree(data) {
      let parents = data.filter((value) => !value.parent);
      //有父节点的数据
      let children = data.filter((value) => value.parent);
      //定义转换方法的具体实现
      let translator = (parents, children) => {
        //遍历父节点数据
        parents.forEach((parent) => {
          //遍历子节点数据
          children.forEach((current, index) => {
            //此时找到父节点对应的一个子节点
            if (current.parent === parent.id) {
              //  current.slideLeft=0
              //对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
              let temp = JSON.parse(JSON.stringify(children));
              //让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
              temp.splice(index, 1);
              //让当前子节点作为唯一的父节点，去递归查找其对应的子节点
              translator([current], temp);
              //把找到子节点放入父节点的children属性中
              typeof parent.children !== "undefined"
                ? parent.children.push(current)
                : (parent.children = [current]);
            }
          });
        });
      };
      //调用转换方法
      translator(parents, children);
      //返回最终的结果

      return parents;
    },
    addTaskList(row) {
      let curId = row.id;
      let parentId = row.parent;
      let type = row.realType || row.type;
      let that = this;
      function getTreeItem(data, id) {
        data.map((item) => {
          if (item.id == parentId) {
            if (item.children) {
              item.children.forEach((pchild, pindex) => {
                if (pchild.id == curId) {
                  let addOne = item.children.filter((child) => {
                    return child.addType == "addInput";
                  });
                  if (type != "cloudccMilestone") {
                    if (!addOne.length) {
                      item.children.splice(pindex, 0, {
                        type: "add",
                        id: pchild.id,
                        parent: parentId,
                        curFrom: "right",
                        children: [],
                        addType: "addInput",
                        realType: type,
                        number: pindex + 1,
                      });
                    } else {
                      if (addOne[0].type != "add") {
                        item.children.unshift({
                          type: "add",
                          id: pchild.id,
                          parent: parentId,
                          curFrom: "right",
                          children: [],
                          addType: "addInput",
                          realType: type,
                          number: pindex + 1,
                        });
                      }
                    }
                    setTimeout(() => {
                      that.$refs["addInput"].focus();
                    }, 0);
                  }
                }
              });
            }
          } else {
            getTreeItem(item.children, id);
          }
        });
        return data;
      }
      this.dataList = [...getTreeItem(this.dataList, curId)];
    },
    // 标题右侧操作----添加+
    addProject(data) {
      let curRow = JSON.parse(JSON.stringify(data));
      if (curRow.type === "cloudccMilestone") {
        this.handleNew(curRow);
      } else {
        this.addTaskList(data, "rightAdd");
      }
    },
    // 排序保存
    saveSorting() {
      // 更新数据
      this.getPIdMilestoneData();
      this.showSorting = false;
    },
    // 排序取消
    cancleSorting() {
      this.showSorting = false;
    },
    toggle() {
      this.$refs["fullscreen"].toggle();
    },
    // 临时任务滚动
    handleTemScroll() {
      document.getElementsByClassName(
        "gt-gantt-content-wrap"
      )[0].scrollLeft = 0;
    },
    fullscreenChange(fullscreen) {
      // 改变全屏图标
      this.fullscreenSvg = fullscreen
        ? "#icon-quxiaoquanping"
        : "#icon-quanping";
      this.fullscreen = fullscreen;
    },
    // 重新排序
    handleSort() {
      this.showSorting = true;
    },
    // 点击任务名称滚动条滚动到指定位置
    setScroll(data) {
      let slideLeft = JSON.parse(JSON.stringify(data)).slideLeft;
      document.getElementsByClassName(
        "gt-gantt-content-wrap"
      )[0].scrollLeft = 195;
      setTimeout(() => {
        document.getElementsByClassName("gt-gantt-content-wrap")[0].scrollLeft =
          slideLeft ? slideLeft + 200 : slideLeft;
      }, 200);
    },
    //查看详情
    getDetial(row) {
      this.showDetail = true;
      let rowdata = JSON.parse(JSON.stringify(row));
      this.contentPopover = rowdata.text;
      this.recordObj = rowdata;
    },
    editItem(row) {
      let rowdata = JSON.parse(JSON.stringify(row));
      // this.selectproject = ""
      // 任务label.chatter.task
      // 服务预约this.$i18n.t("label.projectManagement.service.appointment")
      // 里程碑this.$i18n.t("component.milestones.label.title")
      // 项目this.$i18n.t("label.file.item")
      this.id = rowdata.id;
      if (rowdata.type == "serviceappointment") {
        this.objectApi = "ServiceAppointment";
        this.prefix = "s41";
        this.tabName = this.$i18n.t(
          "label.projectManagement.service.appointment"
        );
      } else if (rowdata.type == "cloudccMilestone") {
        this.objectApi = rowdata.type;
        this.prefix = "p02";
        this.tabName = this.$i18n.t("component.milestones.label.title");
      } else if (rowdata.type == "cloudccTask") {
        this.objectApi = rowdata.type;
        this.prefix = "p03";
        this.tabName = this.$i18n.t("label.chatter.task");
      } else if (rowdata.type == "CloudccProject") {
        this.objectApi = rowdata.type;
        this.prefix = "p01";
        this.tabName = this.$i18n.t("label.file.item");
      } else {
        this.objectApi = rowdata.type;
        this.prefix = "p05";
        this.tabName = this.$i18n.t("label.projectManagement.subtasks");
      }
      this.$nextTick(() => {
        this.$refs.createEditObj.edit();
      });
    },
    getRestDays(bd, ed) {
      let d1 = new Date(bd),
        d2 = new Date(ed);
      let dateSpan = d2 - d1;
      let days = parseInt(dateSpan / (24 * 3600 * 1000)) + 1; //计算两个日期间的天数差，加1是为了把结束日期计算在内
      let weeks = parseInt(days / 7, 10);
      let result = weeks * 2;
      if (days % 7 > 0) {
        let leftdays = days % 7;
        let week1 = d1.getDay(); //周日=0，周一=1，依次。。
        if (week1 == 0) {
          //如果第一个日期从周日开始，剩余天数不足一周（7天）
          result += 1;
        } else if (week1 + leftdays > 7) {
          //如果第一个日期从周一到周六，加上剩余天数大于7，表示包含周六和周日，所以有两天
          result += 2;
        } else if (week1 + leftdays == 7) {
          //如果刚好到周六，有一天休息日
          result += 1;
        }
      }
      return days - result;
    },
    //拖拽任务条
    TaskUpdate(task) {
      task.start_date = formatDate(new Date(task.start_date));
      task.end_date = formatDate(new Date(task.end_date));
      let data = {
        id: task.id,
        start_date: task.start_date,
        end_date: task.end_date,
        fulfill_percent: task.progress * 100 + "%",
        status: task.status,
        type: task.type,
      };
      // 修改的为任务、子任务时，状态字段名为task_status
      if (task.type == "cloudccTask" || task.type == "cloudccSubtask") {
        data = {
          id: task.id,
          start_date: task.start_date,
          end_date: task.end_date,
          fulfill_percent: task.progress * 100 + "%",
          task_status: task.status,
          type: task.type,
        };
      }
      if (task.type == "cloudccTask") {
        data.their_milestone = task.parent;
      } else if (task.type == "cloudccSubtask") {
        data.their_task = task.parent;
      }
      if (task.type === "CloudccProject") {
        // 修改服务项目，此时百分比参数名不同，无需检查父任务
        data = {
          id: task.id,
          start_date: task.start_date,
          end_date: task.end_date,
          complete_percentage: task.progress * 100 + "%",
          type: task.type,
          status: task.status,
        };
      }
      // 如果是特殊的里程碑
      if (task.parent === "none") {
        data = {
          id: task.id,
          start_date: task.start_date,
          end_date: task.end_date,
          fulfill_percent: task.progress * 100 + "%",
          type: task.type,
          status: task.status,
          their_project: this.projectId,
        };
      }
      let params = {
        data: JSON.stringify([data]),
        objectApi: task.type,
        marketsea: false,
      };
      let curEnd = [{ ...task }];
      CommonObjApi.save(params).then((res) => {
        if (res.result) {
          if (
            task.type != "CloudccProject" &&
            task.type != "cloudccMilestone"
          ) {
            let that = this;
            // eslint-disable-next-line no-inner-declarations
            function getTreeItem1(data) {
              data.map((item) => {
                if (
                  item.type == "cloudccTask" ||
                  item.type == "cloudccSubtask"
                ) {
                  if (
                    new Date(formatDate(item.end_date)).getTime() >
                    new Date(formatDate(curEnd[0].end_date)).getTime()
                  ) {
                    curEnd = [{ ...item }];
                  }
                  if (
                    new Date(formatDate(item.end_date)).getTime() ==
                    new Date(formatDate(curEnd[0].end_date)).getTime()
                  ) {
                    curEnd.push({ ...item });
                  }
                }
                if (item.children) {
                  getTreeItem1(item.children);
                }
              });
              return data;
            }
            let curList = [...getTreeItem1(this.dataList)];
            // eslint-disable-next-line no-inner-declarations
            function getTreeItem(data) {
              data.map((item) => {
                if (
                  item.type == "cloudccTask" ||
                  item.type == "cloudccSubtask"
                ) {
                  item.color =
                    item.type == "cloudccTask" ? "#00DFC7" : "#3CB3FE";
                  item.color2 =
                    item.type == "cloudccTask" ? "#00C7B2" : "#139BF0";
                  curEnd.forEach((cl) => {
                    if (item.id == cl.id) {
                      item.color = "#FFD15B";
                      item.color2 = "#FA7E24";
                    }
                  });
                }
                if (item.id === task.id && item.name !== "") {
                  item.continue_date = that.getRestDays(
                    task.start_date,
                    task.end_date
                  );
                } else {
                  if (item.children) {
                    getTreeItem(item.children);
                  }
                }
              });
              return data;
            }
            this.dataList = [...getTreeItem(curList)];
          }
          this.$message.success(this.$i18n.t("savesuccess"));
        }
      });
    },
    rowClick: function () {},

    rowDblClick: function () {},

    rowChecked: function () {
      this.bodyStyle = {
        bgColor: "#ffffff",
      };
    },

    moveSlider: function (newValue) {
      let dateTime_start = formatDate(newValue.start_date);
      let dateTime_end = formatDate(newValue.end_date);
      newValue.start_date = dateTime_start;
      newValue.end_date = dateTime_end;
      this.TaskUpdate(newValue);
    },

    clickSlider: function (data) {
      //
      this.clickSliderActive = true;
      this.selectedTask = data;
    },
    //任务条弹窗的取消
    cancel() {
      this.clickSliderActive = false;
    },
    //任务条弹窗的确定
    sure() {
      this.clickSliderActive = false;
    },
    moveProcess: function (newValue) {
      this.TaskUpdate(newValue);
    },

    noTodayError: function () {
      //
    },

    merge3: function (data) {
      if (data.index % 3 === 0) return false;
      return true;
    },

    merge4: function (data) {
      if (data.index % 4 === 0) return false;
      return true;
    },

    merge5: function (data) {
      if (data.index % 5 === 0) return true;
      return false;
    },

    handleClickModify: function () {
      Object.assign(this.dataList[0], {
        startDate: "2021-08-10",
        endDate: "2021-09-21",
      });

      Object.assign(this.dataList[0]["children"][2]["children"][0], {
        startDate: `2021-08-13`,
        endDate: `2021-09-17`,
        name: "孙数据: abcde",
      });
    },

    handleClickInsert: function () {
      // 数组的增减，根级操作直接更新，子级操作需要重新赋值，以促使DOM更新
      // 修改原有数据不需要这样的操作，因为内部使用了Proxy
      this.dataList.unshift({
        index: INDEX++,
        startDate: `2021-08-10`,
        endDate: `2021-20`,
        name: "数据: " + INDEX,
        ttt: {
          a: "s-aaa" + INDEX,
          b: "s-bbb" + INDEX,
        },
        children: [],
      });
    },

    handleClickInsert2: function () {
      this.dataList[0]["children"].unshift({});
      this.dataList = [...this.dataList];
    },

    handleClickDelete: function () {},

    handleClickColor() {
      this.levelColor = ["ffffff", "ffffff", "ffffff"];

      this.headerStyle = {
        bgColor: "ffffff",
      };

      this.bodyStyle = {
        bgColor: "#ffffff",
      };
    },

    handleClickBg() {
      this.isDark = !this.isDark;
    },

    setSelected() {
      this.$refs.gantt.setSelected(this.dataList[0]);
    },
    handleReload() {},
  },
};
</script>

<style lang="scss" scoped>
.move {
  cursor: move;
}
// 浮窗
.hoverBox {
  z-index: 9999;
  position: absolute;
  left: 100px;
  width: 400px;
  overflow: hidden;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(70, 67, 67, 0.25);
  .hoverName {
    padding: 10px;
    line-height: 40px;
  }
  .hoverText {
    padding: 10px;
    background-color: #409eff;
  }
}
// 双虚线样式
.borderBox {
  height: 4px;
  width: 130%;
  margin-left: -60px;
  margin-top: -6px;
}

.borderBox:hover {
  border-top: 1px dotted #006dcc;
  border-bottom: 1px dotted #006dcc;
}
//设置拖拽样式类名
.drag {
  z-index: -1;
}
.scroll-i {
  font-size: 20px;
  margin-right: 12px;
}
.addOne {
  position: relative;
  display: flex;
  flex: 1;
  .addTaskList {
    cursor: pointer !important;
  }
  div {
    color: #006dcc;
  }
  .addInput {
    // border: 1px solid #e6e6e6;
    position: absolute;
    width: calc(var(--row-width) - 20px);
    display: flex;
    flex: 1;
    align-items: center;
    height: 45px;
    background: #fff;
    left: -40px;
    overflow: hidden;
    border: 1px solid #006dcc;
    border-radius: 4px 4px 4px 4px;
    z-index: 1;
    i {
      display: block;
      width: 40px;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer !important;
    }
    ::v-deep .el-input__inner {
      border: 0;
      border: 0;
      color: #333;
      width: 85%;
    }
  }
}
.gattContainer {
  position: relative;
  width: 100%;
  height: 100%;
  color: #2c3e50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .gattContainer-fullscreen {
    height: 100%;
  }
}
.DivJGanttColumn {
  min-width: 200px;
  overflow-y: auto;
}
.line {
  position: absolute;
  background: red;
}
.div-function {
  float: right;
}
.gattContainer-gantt {
  height: 100%;
  .top-div {
    display: flex;
    justify-content: flex-end;
    height: 42px;
    line-height: 42px;
    background: #fff;
  }
  .cardPro-container {
    flex: 1;
  }
  .screenfullImg-container {
    background: #fff;
    width: 100px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    .screenfullImg {
      font-size: 20px;
    }
  }
}
.gattContainer-sort {
  height: 100%;
}
.innerGanttSlider {
  display: flex;
  justify-content: center;
  height: 100%;
  .gantt-text {
    position: absolute;
    z-index: 9;
    top: -20px;
  }
}
.first-column-l {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.first-column-r {
  width: 170px;
  text-align: right;
  display: none;
}
.first-column:hover {
  .first-column-r {
    display: block;
  }
}
.dialog-center {
  /* border-bottom: 2px solid #d8d8d8; */
  display: flex;
  justify-content: space-between;
}

.dialog-center p {
  font-size: 14px;
  /* font-family: SourceHanSansCN-Regular, SourceHanSansCN; */
  font-weight: 400;
  color: #080707;
  line-height: 21px;
}
::v-deep .selectedTaskDialog {
  top: 50%;
  margin-top: -200px;
}
::v-deep .gt-gantt-content-wrap::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  opacity: 0;
  background: #fff;
}
::v-deep .gt-gantt-content-wrap {
  overflow: auto;
}
::v-deep .gt-gantt-content-wrap::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::v-deep .gt-gantt-content-wrap:hover {
  padding-bottom: 1px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
}
</style>
