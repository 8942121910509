<template>
    <div class="no-data">
        <div class="leftBox">
            <div class="imgBox"></div>
            <!-- 无可用任务 -->
            <div class="textBox">{{$t('front-gantt-module-no-tasks-available')}}</div>
            <!-- 通过甘特图可以查看如何随时间安排工作项，它们如何重叠，以及如何关联 -->
            <div>{{$t('front-gantt-module-no-tasks-describe')}}</div>
            <div class="buttonBox">
                <!-- 添加里程碑 -->
                <el-button size="mini" type="primary" @click="add('milepost')">{{$t('front-gantt-module-add-milestone')}}</el-button>
                <!-- 添加任务 -->
                <el-button size="mini" type="primary" @click="add('task')">{{$t('label.emailobject.emaildetail.nextactivity.task')}}</el-button>
            </div>
        </div>
        <div class="bordrBox"></div>
        <div class="rightBox">
            <span class="spanBox" v-for="(item,index) in divVal" :key="index"></span>
        </div>
        <!-- 新建任务 -->
        <!-- 快速新建任务 -->
        <el-dialog
        :title="$t('front-gantt-module-quick-create-task')"
        :visible.sync="addTaskVal"
        :append-to-body="true"
        @close="cancel"
        width="30%">
        <!-- 任务名称 -->
        {{$t('label.schedulejob.setup.schedule.name')}}：<el-input v-model="taskName" style="width:80%" placeholder=""></el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addTaskVal = false">
                <!-- 取 消 -->
                {{$t('label.emailsync.button.cancel')}}</el-button>
            <el-button type="primary" @click="addTask">
                <!-- 确 定 -->
                {{$t('label.tabpage.ok')}}</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import {save} from "./api"
export default {
  name: "no-data",
  props:{
      projectId:{
          type:String,
          default: ''
      }
  },
  data() {
      return {
          taskName: '', // 新建任务名称
          addTaskVal:false, // 新建任务弹窗
          divVal:new Array(70).fill(null), // 右侧span渲染个数
      }
  },
  mounted(){
    //   20px,获取右侧宽度
    let rightBoxWidth=document.getElementsByClassName('rightBox').clientWidth
    let spanNum=rightBoxWidth?rightBoxWidth/20:70
    this.divVal=new Array(spanNum).fill(null)
  },
  methods:{
    //   弹窗关闭，清空任务名称
      cancel(){
          this.taskName=''
      },
    //   添加回调
      add(val){
          switch (val) {
              case 'milepost': 
                //   新建里程碑，调用标准新建
                // eslint-disable-next-line no-case-declarations
                let row= {parent:this.projectId,type:"cloudccMilestone"}
                // this.$parent.handleNew(row)
                this.$emit('handleNew',row)
                break;
              case 'task': 
                //   任务
                this.addTaskVal=true
                break;
          }
      },
    //   新建任务
    addTask(){
        if(this.taskName){
        let params={
            marketsea: "false",
            objectApi: "cloudccTask",
            data:JSON.stringify([{name:this.taskName,their_project:this.projectId}])
        }
        save(params).then(res=>{
            if(res.result){
                this.addTaskVal=false
                // 父组件隐藏no-data组件，更新父组件甘特图数据
                this.$emit('toUpdate')
            }
            
        })
        }else{
            this.$message.error(this.$i18n.t('front-gantt-module-input-task-name'))
        }
    }
  }
};
</script>

<style scoped lang="scss">
.no-data{
    height: calc(100% - 42px);
    width: 100%;
    background-color: #ffffff;
    display: flex;
    .leftBox{
        width: 34vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // justify-content: center;
        align-items: center;
        border: 1px solid #DEDCDA;
        padding: 10px 0;
        .imgBox{
            width: 20vh;
            height: 20vh;
            background: url("~@/assets/images/gantt/no-data.png") no-repeat center center;
            background-size: 20vh 20vh;
        }
        .textBox{
            // margin-top: 30px;
            // margin-bottom: 20px;
            font-weight: bold;
            color: #333333;
            font-size: 20px;
        }
        .buttonBox{
            // margin-top: 24px;
            display: flex;
            justify-content: center;
        }
    }
    .bordrBox{
        width: 9px;
        background: #ECF5FF;
    }
    .rightBox{
        flex: 1;
        border: 1px solid #DEDCDA;
        overflow: hidden;
        // display: flex;
        .spanBox{
            display: inline-block;
            border-right: 1px solid #DEDCDA;
            width: 20px;
            height: 100%;
        }
    }
}
</style>
