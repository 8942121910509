<template>
  <div
    :class="{ 'more-btn-wrap': true, 'turn-animation': turn }"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
    @click="handleClick"
  >
    <svg
      v-if="['jz_more', 'jz_today', 'jz_operation'].includes(icon)"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      data-darkreader-inline-fill=""
      :width="svgSize"
      :height="svgSize"
      :style="{ 'margin-top': `${margin}px` }"
    >
      <path
        v-if="icon === 'jz_more'"
        d="M214.454294 465.444178C184.742896 495.278195 184.508281 538.709142 213.896235 568.769519L630.140786 994.538833C663.837762 1029.006916 724.335781 1033.945291 765.26679 1005.569011 806.197864 977.192667 812.062156 926.247186 778.365179 891.779102L362.120628 466.009725 361.562505 569.335066 796.279068 132.824523C830.346974 98.61595 825.033253 47.627654 784.410393 18.939002 743.787596-9.749714 683.238763-5.275002 649.170792 28.933571L214.454294 465.444178Z"
        p-id="8684"
      ></path>

      <!-- "today"是一套，一个框、一个字 -->
      <path
        v-if="icon === 'jz_today'"
        d="M914.288 73.136H731.44V36.576C731.44 14.624 716.8 0 694.88 0c-21.968 0-36.592 14.624-36.592 36.576v36.576H365.712V36.576C365.712 14.624 351.088 0 329.136 0c-21.936 0-36.576 14.624-36.576 36.576v36.576H109.712C51.2 73.136 0 124.336 0 182.864v658.288c0 58.496 51.2 109.712 109.712 109.712h804.576C972.8 950.88 1024 899.664 1024 841.152V182.864c0-58.528-51.2-109.728-109.712-109.728zM950.88 841.12c0 21.968-14.656 36.592-36.592 36.592H109.712c-21.936 0-36.576-14.624-36.576-36.592V182.864c0-21.952 14.64-36.576 36.576-36.576h182.864v36.576c0 21.936 14.64 36.576 36.576 36.576 21.952 0 36.576-14.64 36.576-36.576V146.288h292.592v36.576c0 21.936 14.624 36.576 36.56 36.576s36.576-14.64 36.576-36.576V146.288h182.864c21.936 0 36.56 14.624 36.56 36.576V841.12z"
        p-id="11558"
      ></path>
      <path
        v-if="icon === 'jz_today'"
        d="M255.056 512.256c115.04-62.592 186.416-140.528 214.112-233.76 10.176-1.44 37.856 0.752 83.008 6.56 2.912 0 5.088 0.752 6.56 2.176 0 1.472-0.752 3.664-2.192 6.56l-10.928 17.472c46.592 83.024 121.6 139.104 225.024 168.24-14.576 16.048-28.4 41.52-41.52 76.464-99.056-42.24-171.872-102.688-218.48-181.344-58.272 80.128-125.28 144.208-201.008 192.272-14.528-20.4-32.72-38.592-54.576-54.64z m222.864 246.88c39.328-43.696 72.08-88.832 98.304-135.456h-240.32v-59.008h360.496c-40.8 83.024-88.864 159.504-144.208 229.408-32.048-16.032-56.8-27.68-74.272-34.944z m21.84-211.92c-13.104-39.328-24.784-66.992-34.96-83.024l74.288-21.84c4.352 8.736 10.16 22.592 17.472 41.52 8.752 18.944 14.528 32.768 17.472 41.504l-74.272 21.84z"
        p-id="11559"
      ></path>

      <path
        v-if="icon === 'jz_operation'"
        d="M431.79 173A20.24 20.24 0 0 1 452 193.21v223.58A20.24 20.24 0 0 1 431.79 437H208.21A20.24 20.24 0 0 1 188 416.79V193.21A20.24 20.24 0 0 1 208.21 173h223.58m0-60H208.21A80.21 80.21 0 0 0 128 193.21v223.58A80.21 80.21 0 0 0 208.21 497h223.58A80.21 80.21 0 0 0 512 416.79V193.21A80.21 80.21 0 0 0 431.79 113z m323.71 33.2a20.12 20.12 0 0 1 14.29 5.92l129.33 129.32a20.22 20.22 0 0 1 0 28.59L769.79 439.35a20.21 20.21 0 0 1-28.58 0L611.88 310a20.22 20.22 0 0 1 0-28.59l129.33-129.29a20.12 20.12 0 0 1 14.29-5.92m0-60a80 80 0 0 0-56.72 23.49L569.46 239a80.21 80.21 0 0 0 0 113.44l129.32 129.34a80.22 80.22 0 0 0 113.44 0l129.32-129.32a80.21 80.21 0 0 0 0-113.44L812.22 109.69A80 80 0 0 0 755.5 86.2zM431.79 587A20.24 20.24 0 0 1 452 607.21v223.58A20.24 20.24 0 0 1 431.79 851H208.21A20.24 20.24 0 0 1 188 830.79V607.21A20.24 20.24 0 0 1 208.21 587h223.58m0-60H208.21A80.21 80.21 0 0 0 128 607.21v223.58A80.21 80.21 0 0 0 208.21 911h223.58A80.21 80.21 0 0 0 512 830.79V607.21A80.21 80.21 0 0 0 431.79 527z m416 60A20.24 20.24 0 0 1 868 607.21v223.58A20.24 20.24 0 0 1 847.79 851H624.21A20.24 20.24 0 0 1 604 830.79V607.21A20.24 20.24 0 0 1 624.21 587h223.58m0-60H624.21A80.21 80.21 0 0 0 544 607.21v223.58A80.21 80.21 0 0 0 624.21 911h223.58A80.21 80.21 0 0 0 928 830.79V607.21A80.21 80.21 0 0 0 847.79 527z"
        p-id="13070"
      ></path>
    </svg>

    <!-- TODO: 适配支持 meterial-icon 的图标集 -->
    <svg v-else />
  </div>
</template>

<script>
export default {
  name: "MoreBtn",

  props: {
    icon: {
      type: String,
      required: true
    },
    size: Number,
    turn: Boolean
  },

  beforeCreate () { },

  created () { },

  beforeMount () { },

  mounted () { },

  beforeDestroy () { },

  destroyed () { },

  data () {
    return {};
  },

  computed: {
    svgSize: function () {
      return parseInt(this.size * 0.5);
    },

    margin: function () {
      return parseInt((this.size - this.svgSize) / 2);
    }
  },

  watch: {},

  methods: {
    handleClick: function (e) {
      this.$emit("click", e);
    }
  },

  components: {}
};
</script>

<style lang="scss" scoped>
.more-btn-wrap {
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 5px 1px grey;
  -webkit-box-shadow: 1px 1px 5px 1px grey;
  -moz-box-shadow: 1px 1px 5px 1px grey;
  &:hover {
    filter: brightness(120%);
    box-shadow: shade(grey, 80%);
  }
  &:active {
    filter: brightness(90%);
    box-shadow: shade(grey, 80%);
  }
  .turn-animation {
    animation: re-turn 0.5s ease-out;
  }

  &:hover {
    animation: turn 0.5s ease-out;
  }

  @keyframes turn {
    0% {
      -webkit-transform: rotate(0deg);
    }

    25% {
      -webkit-transform: rotate(360deg);
    }

    50% {
      -webkit-transform: rotate(540deg);
    }

    75% {
      -webkit-transform: rotate(670deg);
    }

    100% {
      -webkit-transform: rotate(720deg);
    }
  }
}
@keyframes re-turn {
  0% {
    -webkit-transform: rotate(720deg);
  }

  25% {
    -webkit-transform: rotate(360deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(50deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}
</style>
