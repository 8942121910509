import "./index.scss";
import {
  Variables,
  getDateInterval,
  formatDate,
  getDateOffset,
  compareDate,
} from "../../utils";
import Mixins from "../mixins";

export default {
  name: Variables.name.slider,

  props: {
    label: String,
    dateFormat: String,
    flat: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "lightgreen",
    },
    // left, center, right
    alignment: {
      type: String,
      default: "left",
    },
    move: {
      type: Boolean,
      default: false,
    },
    resizeLeft: {
      type: Boolean,
      default: false,
    },
    resizeRight: {
      type: Boolean,
      default: false,
    },
    emptyData: {
      type: String,
      default: Variables.noData,
    },

    linkedResize: Boolean,
  },

  beforeCreate() {},

  created() {
    this.type = this.$parent.rowData.data.type
    this.id=this.$parent.rowData.data.id
    this.start=this.$parent.rowData.start
    this.end=this.$parent.rowData.end
  },

  beforeMount() {},

  mounted() {},

  beforeDestroy() {},

  destroyed() {},

  inject: ["pd", "gd", "root"],

  mixins: [Mixins],

  data() {
    return {
      showCtrlChunk: false,
      type:"",
      id:"",
      hide:false,
      mouseup_click_debug:null
    };
  },

  computed: {
    leftColor(){
      return this.$parent.rowData.data.color2
    },
    realAlignment: function() {
      switch (this.alignment) {
        case "right":
          return "flex-end";
        case "center":
          return "center";
        default:
          return "flex-start";
      }
    },

    /**
     * 计算滑块的宽度
     */
    sliderWidth: function() {
      return (
       ((getDateInterval(this.$parent.rowData.start, this.$parent.rowData.end) /
          Variables.time.millisecondOfDay)+1) *
        this.pd.ganttOptions[Variables.key.columnWidth]
      );
    },
    // 计算完成进度的宽度
    sliderResizeLeft: function() {
      return (
        ((getDateInterval(this.$parent.rowData.start, this.$parent.rowData.end) /
          Variables.time.millisecondOfDay) +1)*
        this.pd.ganttOptions[Variables.key.columnWidth] *
        this.$parent.rowData.progress
      );
    },
    /**
     * 计算滑块的初始偏移量
     */
    sliderLeft: function() {
      // 最左侧一定是一个整天的宽度，将时间调整为0时，保证没有位移。非0会产生位移
      let sd = new Date(formatDate(this.gd.start));
      sd = sd.setHours(0) - sd.getTimezoneOffset();
      return (
        (getDateInterval(sd, this.$parent.rowData.start) /
          Variables.time.millisecondOfDay) *
        this.pd.ganttOptions[Variables.key.columnWidth]
      );
    },

    isCustomDefaultScoped: function() {
      return (
        this.$scopedSlots &&
        Object.prototype.hasOwnProperty.call(this.$scopedSlots, "default")
      );
    },

    isCustomContentScoped: function() {
      return (
        this.$scopedSlots &&
        Object.prototype.hasOwnProperty.call(this.$scopedSlots, "content")
      );
    },

    isCustomLeftChunk: function() {
      return (
        this.$scopedSlots &&
        Object.prototype.hasOwnProperty.call(this.$scopedSlots, "left")
      );
    },

    isCustomRightChunk: function() {
      return (
        this.$scopedSlots &&
        Object.prototype.hasOwnProperty.call(this.$scopedSlots, "right")
      );
    },

    sliderContent: function() {
      return this.isCustomDefaultScoped
        ? this.$scopedSlots.default(this.scopeData)
        : this.isCustomContentScoped
        ? this.$scopedSlots.content(this.scopeData)
        : this.textData;
    },
  },

  watch: {
    sliderWidth: function(val) {
      this.hide=!val;
      this.setBetweenDate();
    },
    sliderLeft: {
      handler(val) {
        if (!val) {
          val = 0;
        }
        this.$parent.rowData.setLeft(val);
      },
      immediate: true,
    },
  },

  methods: {
    getMoveChunkStyle(f) {
      if (f) {
        return { display: 'block', height: "100%" };
      } else {
        return { display: 'none' };
      }
    },

    setBetweenDate() {
      // 左边界
      if (compareDate(this.$parent.rowData.start, this.gd.start) === "l") {
        this.gd.start = this.$parent.rowData.start;
      }

      // 右边界
      if (compareDate(this.$parent.rowData.end, this.gd.end) === "r") {
        this.gd.end = this.$parent.rowData.end;
      }

      this.root.setHeaders();
    },

    /**
     * 移动处理
     * @param {Event} e
     * @param {String} flag
     */
    sliderMoveHandle: function(e, flag = "") {
      const srcX = e.pageX;
      const srcStartDate = new Date(this.$parent.rowData.start);
      const srcEndDate = new Date(this.$parent.rowData.end);

      document.onmousemove = (e) => {
        let targetX = e.pageX;
        // 如果鼠标离从左侧离开浏览器, 那么鼠标的位置停留在浏览器最左侧的位置, 也就是targetX = 0.
        if (targetX < 0) {
          targetX = 0;
        }

        const offset = parseInt(((targetX - srcX) / this.pd.ganttOptions[Variables.key.columnWidth]) )*Variables.time.millisecondOfDay
      

        if (flag === "move" || flag === "left") {
          this.$parent.rowData.setStart(
            getDateOffset(srcStartDate, offset),
            this.linkedResize
          );
        }

        if (flag === "move" || flag === "right") {
          this.$parent.rowData.setEnd(
            getDateOffset(srcEndDate, offset),
            this.linkedResize
          );
        }
      };

      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null;
        if((this.$parent.rowData.start.getTime()==srcStartDate.getTime())&&(this.$parent.rowData.end.getTime()==srcEndDate.getTime())){
          return
        }
        this.mouseup_click_debug = true;
        setTimeout(()=> {
          this.mouseup_click_debug = false;
        }, 200);
        this.root.IFMoveSlider(this.$parent.rowData, {
          start: srcStartDate,
          end: srcEndDate,
        });
        this.setBetweenDate();
      };
    },

    // 改变内部滑块大小
    sliderInnerResizeHandle: function() {
      let that = this;
      let _index = this.$parent.rowData.uindex;
      let oDiv = document.getElementsByClassName("gt-slider-resizor-left")[
        _index
      ];
      let oDivslider = document.getElementsByClassName("gt-slider-resizor")[
        _index
      ];
      // 获取event对象，兼容性写法
      let ev = ev || event;
      // 鼠标按下时的位置
      let mouseDownX = ev.pageX;
      // 方块上下左右四个边的位置和方块的长宽
      // let L0 = oDiv.offsetLeft;
      // let R0 = oDiv.offsetLeft + oDiv.offsetWidth;
      let W = oDiv.offsetWidth;
      // 设置方块的识别范围
      // let areaL = L0 + 22;
      // let areaR = R0 - 22;
      // 判断改变方块的大小的方向
      // 左
      // let changeL = mouseDownX <= areaL;
      // 右
      // let changeR = mouseDownX >= areaR;
      // IE8 取消默认行为-设置全局捕获
      if (oDiv.setCapture) {
        oDiv.setCapture();
      }
      document.onmousemove = function(e) {
        let ev = e || event;
        // 鼠标移动时的鼠标位置
        let mouseMoveX = ev.pageX;
        let divWidth = 0;
        //根据改变方块大小的方向不同进行大小的改变
        // // 左
        // if(changeL){
        //   divWidth= (mouseDownX - mouseMoveX) + W;
        // }
        // // 右
        // if(changeR){
        divWidth = mouseMoveX - mouseDownX + W;
        // }
        // 限定范围
        if (parseInt(oDiv.style.width) < 0) {
          divWidth = 0;
        }
        if (divWidth > that.sliderWidth) {
          divWidth = that.sliderWidth;
        }
        oDiv.style.width = divWidth + "px";
        oDivslider.style.left =
          divWidth >= 0 ? divWidth - 12 + "px" : -4 + "px";
      };
      document.onmouseup = function() {
        document.onmousemove =document.onmouseup =  null;
        // 释放全局捕获
        if (oDiv.releaseCapture) {
          oDiv.releaseCapture();
        }
        that.mouseup_click_debug = true;
        setTimeout(()=> {
          that.mouseup_click_debug = false;
        }, 200);
        let _progress=parseInt(oDiv.style.width.split("px")[0]/that.sliderWidth*100);
        that.$parent.rowData.setProcess((_progress/100).toFixed(1))
        that.root.IFProcessMove(that.$parent.rowData, {
          progress: (_progress/100).toFixed(1)
        });
      };
      return false;
    },

    onMouseDown: function(e) {
      e.stopPropagation();
      this.sliderMoveHandle(e, "move");
    },

    onLeftChunkMouseDown: function(e) {
      e.stopPropagation();
      this.sliderMoveHandle(e, "left");
    },

    onRightChunkMouseDown: function(e) {
      e.stopPropagation();
      this.sliderMoveHandle(e, "right");
    },

    onMouseEnter: function() {
      this.showCtrlChunk = true;
    },

    onMouseLeave: function() {
      this.showCtrlChunk = false;
    },
    sliderClick: function() {
      if(this.mouseup_click_debug) {
        return false;
      }
      this.root.IFClickSlider(this.$parent.rowData);
    },
    onMouseResizor: function(e) {
      e.stopPropagation();
      this.sliderInnerResizeHandle(e);
    },
  },

  components: {},

  render(h) {
    return h(
      "div",
      {
        class: {
          "gt-slider": true,
          "gt-shadow": !this.flat,
          "gt-project": this.type=='CloudccProject',
          "gt-milestone": this.type=='cloudccMilestone',
        },
        style: {
          width: `${this.sliderWidth}px`,
          left: `${this.sliderLeft}px`,
          "background-color": !this.isCustomContentScoped ? this.bgColor : "",
          "display":this.hide?'none':'block'
        },
        on: {
          mousedown: this.move ? this.onMouseDown : () => {},
          mouseenter: this.onMouseEnter,
          mouseleave: this.onMouseLeave,
          click:this.sliderClick
        },
      },
      [
        this.type=='cloudccMilestone'&&this.id!='none'?
        h(
          "div",
          {
            class: {
              "gt-left-arrow": true,
            },
            style: {
              'border-left-color': '#ef716c',
            },
          },
        ):null,
        this.type=='cloudccMilestone'&&this.id!='none'?
        h(
          "div",
          {
            class: {
              "gt-right-arrow": true,
            },
            style: {
              'border-right-color': '#ef716c',
            },
          },
        ):null,
        h(
          "div",
          {
            class: {
              "gt-slider-content": !this.isCustomContentScoped,
              "gt-custom-slider-content": this.isCustomContentScoped,
              "gt-noselect": true,
              "gt-text-nowrap": true
            },
            style: {
              "justify-content": this.realAlignment,
            },
            on: {
              onselectstart: () => {
                return false;
              },
            },
          },
          this.sliderContent
        ),
        h("div", {
          class: { "gt-slider-resizor-left": true },
          style: {
            width: this.sliderResizeLeft + "px",
            "background-color": this.leftColor
          },
          on: {
            mousedown: () => {},
          },
        }),
        h("div", {
          class: { "gt-slider-resizor": true, "el-icon-caret-top": true },
          style: {
            left: this.sliderResizeLeft - 12 + "px",
          },
          on: {
            mousedown: this.onMouseResizor,
          },
        }),
        this.isCustomLeftChunk
          ? h(
              "div",
              {
                style: {
                  position: "absolute",
                  transition: "all 0.2s",
                  left: "0",
                  "z-index": "1",
                  ...this.getMoveChunkStyle(
                    this.showCtrlChunk && this.resizeLeft
                  ),
                },
              },
              this.$scopedSlots.left(this.scopeData)
            )
          : h("div", {
              class: { "gt-slider-ctrl__left": true },
              style: {
                // "background-color": this.bgColor,
                ...this.getMoveChunkStyle(
                  this.showCtrlChunk && this.resizeLeft
                ),
              },
              on: {
                mousedown: this.resizeLeft
                  ? this.onLeftChunkMouseDown
                  : () => {},
              },
            }),

        this.isCustomRightChunk
          ? h(
              "div",
              {
                style: {
                  position: "absolute",
                  transition: "all 0.2s",
                  right: "0",
                  "z-index": "1",
                  ...this.getMoveChunkStyle(
                    this.showCtrlChunk && this.resizeRight
                  ),
                },
              },
              this.$scopedSlots.right(this.scopeData)
            )
          : h("div", {
              class: { "gt-slider-ctrl__right": true },
              style: {
                // "background-color": this.bgColor,
                ...this.getMoveChunkStyle(
                  this.showCtrlChunk && this.resizeRight
                ),
              },
              on: {
                mousedown: this.resizeRight
                  ? this.onRightChunkMouseDown
                  : () => {},
              },
            }),
      ]
    );
  },
};
